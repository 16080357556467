import { Controller } from 'stimulus';

export default class extends Controller {
  fetch(event) {
    event.preventDefault();
    fetch(event.target.href, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json'
      }
    }).then(response => response.text())
      .then((html) => {
        this.element.insertAdjacentHTML('beforebegin', html);
        this.newTarget = this.element.previousElementSibling;
        this.newTarget.classList.add('flash-opacity');
        this.element.remove();
      });
  }
}
