import { Controller } from 'stimulus';
import moment from 'moment-timezone';

const { timeZone } = Window;

function localizeElement(e) {
  const element = e;
  const format = element.getAttribute('data-format');
  const dateTime = element.getAttribute('data-utc-datetime');
  const formattedDateTime = localizeDateTime(dateTime, format);

  element.innerHTML = `
    <span class="date text-nowrap">${formattedDateTime.date || ''}</span>
    <span class="time text-nowrap">${formattedDateTime.time || ''}</span>
  `;
}

export function localizeDateTime(utcDateTime, format) {
  const dateTime = moment(utcDateTime).tz(timeZone);
  return formatDateTime(dateTime, format);
}

function formatDateTime(dateTime, format) {
  switch (format) {
    case 'time':
      return { time: dateTime.format('h:mm A (z)') };
    case 'default_with_day_of_week':
      return { date: dateTime.format('ddd, M/D/YY'), time: dateTime.format('h:mm A (z)') };
    case 'default_with_seconds':
      return { date: dateTime.format('ddd, M/D/YY'), time: dateTime.format('h:mm:ss A (z)') };
    default:
      return { date: dateTime.format('M/D/YY'), time: dateTime.format('h:mm A (z)') };
  }
}

export default class extends Controller {
  static targets = ['localizable'];

  connect() {
    this.localizeTargets();
    window.addEventListener('localizeTime', () => { this.localizeTargets(); });
  }

  localizeTargets() {
    this.localizableTargets.forEach((e) => {
      localizeElement(e);
    });
  }
}
