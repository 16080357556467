import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'alertMessage', 'contactInput', 'form', 'nameInput', 'phoneInput', 'submitButton'
  ];

  alertMessage(message, hidden = false) {
    this.alertMessageTarget.innerHTML = message;
    this.alertMessageTarget.hidden = hidden;
  }

  invalidFields() {
    const errorMessages = [];

    if (this.nameInputTarget.value.length === 0) { errorMessages.push("Bank Name can't be blank <br>"); }
    if (this.contactInputTarget.value.length === 0) { errorMessages.push("Contact can't be blank <br>"); }
    if (this.phoneInputTarget.value.length === 0) { errorMessages.push("Phone can't be blank <br>"); }
    if (errorMessages.length > 0) {
      this.alertMessage(errorMessages.join(' '));
      return true;
    }

    return false;
  }

  checkFields(event) {
    event.preventDefault();
    this.alertMessage('', true);
    this.element.scrollTo(0, 0);
    if (this.invalidFields()) { return; }

    this.formTarget.submit();
  }
}
