import { Controller } from 'stimulus';
import { localizeDateTime } from './datetime_localization_controller';
import consumer from '../channels/consumer';

let pageChange;
let timeRemaining;

window.addEventListener('beforeunload', () => {
  pageChange = true;
});

export default class extends Controller {
  static targets = ['countdown', 'endsAt', 'fixedStatusDisconnected', 'outbidLots', 'outbidModal', 'statusConnected', 'statusDisconnected'];

  connect() {
    console.log(`[CONTROLLER][CONNECT] subscribe: ${this.element.dataset.subscribe}`);
    if (this.element.dataset.subscribe !== 'true') return;

    setInterval(() => {
      if (typeof timeRemaining !== 'undefined' && timeRemaining != null && timeRemaining !== '') {
        console.log(`Time remaining: ${timeRemaining}`);
        timeRemaining -= 1;
        this.updateCountdownElements();
      }
    }, 1000);

    const lotIndexController = this;
    this.subscription = consumer.subscriptions.create({ channel: 'EventChannel', id: this.element.dataset.eventId }, {
      connected() {
        console.log('[CABLE][CONNECTED]');
        lotIndexController.fixedStatusDisconnectedTarget.hidden = true;
        lotIndexController.statusDisconnectedTarget.hidden = true;
        lotIndexController.statusConnectedTarget.hidden = false;
      },

      received(data) {
        console.log('[CABLE][RECEIVED]');
        console.dir(data);

        // Check for page refresh
        if (data.refresh?.toString() === 'true' && (!data.lot_id || lotIndexController.element.querySelector(`[data-id='${data.lot_id}']`))) {
          console.log('[CABLE][RECEIVED][REFRESH]');
          window.location.reload();
          return;
        }

        // Handle timeRemaining updates
        if (data.time_remaining) {
          console.log(`[CABLE][RECEIVED][TIME REMAINING] time_remaining: ${data.time_remaining}`);
          timeRemaining = parseInt(data.time_remaining, 10);

          // Handle endsAt updates
          if (data.ends_at) {
            console.log(`[CABLE][RECEIVED][ENDS AT] ends_at: ${data.ends_at}`);
            lotIndexController.updateEndsAtElements(data.ends_at);
          }
        }

        // Handle single lot updates
        if (data.lot_id) {
          console.log(`[CABLE][RECEIVED][LOT] lot_id: ${data.lot_id}, current_bid: ${data.current_bid}, high_bidder: ${data.high_bidder}`);
          lotIndexController.updateLot(data.lot_id, data.current_bid, data.high_bidder);
        }

        // Handle lastUpdate updates
        if (data.last_update) {
          console.log(`[CABLE][RECEIVED][LAST UPDATE] last_update: ${data.last_update}`);
          const formattedDateTime = localizeDateTime(data.last_update, 'default_with_seconds');
          const localizedLastUpdateHTML = `${formattedDateTime.date || ''} ${formattedDateTime.time || ''}`;
          document.querySelector('span.update-popover').dataset.content = localizedLastUpdateHTML;
        }
      },

      disconnected() {
        console.log('[CABLE][DISCONNECTED]');
        if (pageChange !== true) {
          lotIndexController.fixedStatusDisconnectedTarget.hidden = false;
          lotIndexController.statusDisconnectedTarget.hidden = false;
          lotIndexController.statusConnectedTarget.hidden = true;
        }
      }
    });
  }

  disconnect() {
    this.subscription.unsubscribe();
  }

  updateEndsAtElements(endsAt) {
    const formattedDateTime = localizeDateTime(endsAt, (timeRemaining < 18000) ? 'time' : 'default');
    const localizedEndsAtHTML = `${formattedDateTime.date || ''} ${formattedDateTime.time || ''}`;
    console.log(localizedEndsAtHTML);

    this.endsAtTargets.forEach((element) => {
      element.innerHTML = localizedEndsAtHTML;
    });
  }

  updateCountdownElements() {
    const countdownHTML = this.countdownHTML();

    if (countdownHTML) {
      this.countdownTargets.forEach((element) => {
        element.innerHTML = countdownHTML;
      });
    }
  }

  countdownHTML() {
    const hours = Math.floor(timeRemaining / 3600);

    if (hours > 23) return null;
    if (timeRemaining < 0) return ' ';

    const minutes = Math.floor((timeRemaining - (hours * 3600)) / 60);
    const seconds = Math.floor(timeRemaining - (hours * 3600) - (minutes * 60));

    let result = '...in ';
    if (timeRemaining < 60) { // Less than 60 seconds
      result += `<span class='text-danger font-weight-bold'>${seconds}s</span>`;
    } else if (timeRemaining < 300) { // Less than 5 minutes
      result += `${minutes}m`;
      if (seconds > 0) result += ` ${seconds}s`;
    } else if (timeRemaining < 3600) { // Less than 1 hour
      result += `${minutes}m`;
    } else if (timeRemaining <= 86400) { // 24 hours or less
      result += `${hours}h`;
      if (minutes > 0) result += ` ${minutes}m`;
    } else if (minutes > 30) {
      result += `about ${hours + 1} hours`;
    } else {
      result += `about ${hours} hours`;
    }

    return `${result}`;
  }

  updateLot(lotId, currentBid, highBidder) {
    const lotRows = this.element.querySelectorAll(`[data-id='${lotId}']`);
    const userOutbid = (lotRows[0].querySelector('.high-bidder').hidden === false && !highBidder);
    lotRows.forEach((lotRow) => {
      lotRow.querySelector('.current-bid').innerHTML = `Current Bid: ${currentBid}`;
      lotRow.querySelector('.high-bidder').hidden = !highBidder;
    });

    if (!userOutbid) return;

    const lotNumber = lotRows[0].dataset.number;
    if (this.outbidModalTarget.classList.contains('show')) {
      this.outbidLotsTarget.insertAdjacentHTML('beforeend', `<li><h3>Lot ${lotNumber}</h3></li>`);
    } else {
      this.outbidLotsTarget.innerHTML = `<li><h3>Lot ${lotNumber}</h3></li>`;
    }
    $('#outbid-modal').modal('show');
  }

  loadURL(event) {
    if (window.getSelection().toString() || event.target.tagName === 'A') return;

    const url = `lots/${event.currentTarget.dataset.id}`;

    if (event.ctrlKey) {
      window.open(url);
    } else {
      window.location.assign(url);
    }
  }
}
