import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['display']

  submit(e) {
    e.preventDefault();
    Rails.fire(e.currentTarget.parentElement, 'submit');
  }

  handleResults(e) {
    const [_data, _status, xhr] = e.detail;
    this.displayTarget.innerHTML = xhr.response;
  }
}
