import { Controller } from 'stimulus';

export default class extends Controller {
  insertToast(title, body) {
    this.template = document.querySelector('#toast-template');
    this.toast = this.template.cloneNode(true);
    this.toast.querySelector('.toast-title').innerHTML = title;
    this.toast.querySelector('.toast-body').innerHTML = body;
    this.template.parentNode.appendChild(this.toast);
    $(this.toast).toast('show');
  }

  submit(event) {
    event.preventDefault();
    fetch(event.target.href, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json'
      }
    }).then(response => response.text())
      .then((html) => {
        this.element.innerHTML = html;
        this.insertToast(event.target.innerHTML, 'Updated');
      });
  }
}
