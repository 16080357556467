import { Controller } from 'stimulus';

export default class extends Controller {
  show(event) {
    const tagName = event.target.tagName;
    if (window.getSelection().toString() || (!tagName.includes('TD') && !tagName.includes('DIV'))) return;

    if (event.ctrlKey) {
      window.open(event.currentTarget.dataset.url);
    } else {
      window.location = event.currentTarget.dataset.url;
    }
  }
}
