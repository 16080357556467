import { Controller } from 'stimulus';

export default class extends Controller {
  addAssociation(event) {
    event.preventDefault();
    const templateFields = document.getElementById(event.target.getAttribute('data-template-id') || 'template-fields');
    const target = event.target.parentElement.querySelector('.nested-fields-container') || event.target;
    target.insertAdjacentHTML((event.target === target ? 'beforebegin' : 'beforeend'), templateFields.innerHTML.replace(/new_record/g, new Date().getTime()));
    target.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  removeAssociation(event) {
    event.preventDefault();
    const wrapper = event.target.closest(`.${event.target.getAttribute('data-wrapper-id') || 'nested-fields'}`);

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove();
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.hidden = true;
    }
  }
}
