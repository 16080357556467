import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['browserSummary', 'userAgent', 'supportLink'];

  connect() {
    $('#user-agent-modal').on('hidden.bs.modal', () => {
      this.browserSummaryTarget.innerHTML = '';
      this.userAgentTarget.innerHTML = '';
      this.supportLinkTarget.innerHTML = '';
    });
  }

  displayModal(event) {
    const targetDataset = event.target.dataset;
    event.preventDefault();
    this.browserSummaryTarget.innerHTML = targetDataset.browserSummary;
    this.userAgentTarget.innerHTML = targetDataset.userAgent;
    const supportLink = document.createElement('a');
    supportLink.innerHTML = 'Simulate user agent on support page';
    supportLink.href = event.target.href;
    supportLink.target = '_blank';
    supportLink.rel = 'noopener';
    this.supportLinkTarget.appendChild(supportLink);
    $('#user-agent-modal').modal('show');
  }
}
