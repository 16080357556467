import TableController from './table_controller';

export default class extends TableController {
  static targets = ['source', 'filterable'];

  queueUpdate() {
    if (this.inputTimeout) {
      clearTimeout(this.inputTimeout);
    }

    this.inputTimeout = setTimeout(() => {
      this.updateResults();
    }, 500);
  }

  updateResults() {
    const query = this.sourceTarget.value.toLowerCase();
    if (query.length === 1) return;

    this.filterableTargets.forEach((e) => {
      const element = e;
      const searchableContent = element.getAttribute('data-searchable-content').toLowerCase();
      const match = searchableContent.includes(query);
      element.style.display = match ? '' : 'none';
    });

    this.restripeTable();
  }
}
