import { Controller } from 'stimulus';

export default class extends Controller {
  selectAll(event) {
    document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      if (checkbox.dataset.selectAllCheckbox !== 'false') {
        checkbox.checked = event.target.checked;
      }
    });
  }
}
