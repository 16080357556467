import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'selectedMassOptionsContainer', 'selectedMassOptionsMessage', 'selectAllCheckbox']

  connect() {
    this.toggleMassOptions(true);
  }

  insertToast(title, body) {
    this.template = document.querySelector('#toast-template');
    this.toast = this.template.cloneNode(true);
    this.toast.querySelector('.toast-title').innerHTML = title;
    this.toast.querySelector('.toast-body').innerHTML = body;
    this.template.parentNode.appendChild(this.toast);
    $(this.toast).toast('show');
  }

  submit(event) {
    event.preventDefault();
    const elementId = event.target.getAttribute('data-element-id');
    const value = (event.target.getAttribute('data-value') === 'true'); // Set boolean datatype
    const mediaIds = [];
    const selectedCheckBoxes = [];
    this.checkboxTargets.forEach((checkbox) => { if (checkbox.checked) { selectedCheckBoxes.push(checkbox); mediaIds.push(checkbox.value); } });
    if (selectedCheckBoxes.length > 0) {
      const params = { value, media_ids: mediaIds };
      const controller = this;
      fetch(event.target.href, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
      }).then((data) => {
        if (data.status >= 200 && data.status < 400) {
          selectedCheckBoxes.forEach((checkbox) => {
            const lotId = checkbox.value;
            const displayCell = controller.element.querySelector(`#${elementId.replace('_', '-')}-${lotId}`);
            displayCell.innerHTML = ((value === true) ? 'Yes' : 'No');
          });
          this.insertToast(event.target.innerHTML, 'Selected videos updated');
        }
      });
    } else {
      this.insertToast(`${event.target.innerHTML} Error`, 'No videos selected');
    }
  }

  toggleAllLotCheckboxes() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = this.selectAllCheckboxTarget.checked;
    });
  }

  toggleHeaderCheckbox() {
    const checkBoxesChecked = [...new Set(this.checkboxTargets.map(checkbox => checkbox.checked))];
    if (checkBoxesChecked.includes(true) && checkBoxesChecked.includes(false)) {
      this.selectAllCheckboxTarget.indeterminate = true;
      this.toggleMassOptions(false);
    } else if (checkBoxesChecked.includes(true)) {
      this.selectAllCheckboxTarget.indeterminate = false;
      this.selectAllCheckboxTarget.checked = true;
      this.toggleMassOptions(false);
    } else {
      this.selectAllCheckboxTarget.indeterminate = false;
      this.selectAllCheckboxTarget.checked = false;
      this.toggleMassOptions(true);
    }
  }

  toggleMassOptions(hide) {
    this.selectedMassOptionsContainerTarget.hidden = hide;
    this.selectedMassOptionsMessageTarget.hidden = !hide;
  }
}
