import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['lotCheckbox', 'selectAllCheckbox', 'submitButton', 'toEventSelect']

  toggleCheckBox(event) {
    if (event.target.tagName === 'INPUT') return;

    const checkBox = event.currentTarget.querySelector("input[name='copy[lot_ids][]'");
    checkBox.checked = !checkBox.checked;
  }

  toggleSubmitButton() {
    if (this.lotCheckboxTargets.some(checkBox => checkBox.checked === true) && this.toEventSelectTarget.value !== '') {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }
}
