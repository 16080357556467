import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['table'];

  restripeTable() {
    let visibleIndex = 0;

    this.tableTarget.querySelectorAll('tr').forEach((e) => {
      const element = e;
      if (element.offsetWidth > 0 && element.offsetHeight > 0) {
        element.classList.remove('even', 'odd');
        const newClass = (visibleIndex % 2) ? 'even' : 'odd';
        element.classList.add(newClass);
        visibleIndex += 1;
      }
    });
  }
}
