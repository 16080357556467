import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['submitButton', 'toEventSelect']

  toggleSubmitButton() {
    if (this.toEventSelectTarget.value !== '') {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }
}
