import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['submitButton', 'uploadField', 'uploadMessage']

  connect() {
    this.uploadFieldTarget.onchange = (event) => {
      if (event.srcElement.files[0]?.size > 1038336) {
        this.submitButtonTarget.disabled = true;
        this.uploadMessageTarget.hidden = false;
      } else {
        this.submitButtonTarget.disabled = false;
        this.uploadMessageTarget.hidden = true;
      }
    };
  }
}
