import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'approvalPreference', 'cattleAmount', 'cattleCheckbox',
    'deerAmount', 'deerCheckbox', 'horsesAmount',
    'horsesCheckbox', 'sheepAmount', 'sheepCheckbox'
  ];

  connect() {
    this.showTarget(this.cattleCheckboxTarget.checked, this.cattleAmountTarget);
    this.showTarget(this.deerCheckboxTarget.checked, this.deerAmountTarget);
    this.showTarget(this.horsesCheckboxTarget.checked, this.horsesAmountTarget);
    this.showTarget(this.sheepCheckboxTarget.checked, this.sheepAmountTarget);
  }

  showTarget(trueOrFalse, targetElement) {
    const element = targetElement;

    element.hidden = !trueOrFalse;
  }

  showBidding(event) {
    this.showTarget(event.target.value === 'false', this.approvalPreferenceTarget);
  }

  showCattle(event) {
    this.showTarget(event.target.checked, this.cattleAmountTarget);
  }

  showDeer(event) {
    this.showTarget(event.target.checked, this.deerAmountTarget);
  }

  showHorses(event) {
    this.showTarget(event.target.checked, this.horsesAmountTarget);
  }

  showSheep(event) {
    this.showTarget(event.target.checked, this.sheepAmountTarget);
  }
}
