import { Controller } from 'stimulus';
import { Sortable, MultiDrag } from 'sortablejs';

Sortable.mount(new MultiDrag());

export default class extends Controller {
  static targets = ['notInSaleOrderColumn', 'saleOrderColumn'];

  connect() {
    this.createSortable(this, this.saleOrderColumnTarget);
    this.createSortable(this, this.notInSaleOrderColumnTarget);
  }

  createSortable(_this, target) {
    Sortable.create(target, {
      animation: 100,
      group: 'shared',
      multiDrag: true,
      onEnd() {
        _this.updatePositions();
      }
    });
  }

  updatePositions() {
    Array.from(this.saleOrderColumnTarget.getElementsByClassName('position-field')).forEach((element, index) => {
      element.value = index + 1;
    });
    Array.from(this.notInSaleOrderColumnTarget.getElementsByClassName('position-field')).forEach((element) => {
      element.value = '';
    });
  }
}
