import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['banner', 'fileNames', 'input', 'row', 'submitButton'];

  autoStandardize(event) {
    event.preventDefault();
    this.value = this.inputTarget.value.split('.');
    this.parenthesesContent = this.inputTarget.value.match(/\(.*\)/g);
    this.fileName = this.value[0].replace(this.parenthesesContent, '').trim().replace(/lot[-_\s]*/g, '').replace(/\s+/g, '-');
    this.extension = this.value[this.value.length - 1].replace(/\s+/g, '');
    if (this.parenthesesContent) {
      this.inputTarget.value = `${this.fileName} ${this.parenthesesContent.replace(/\s+/g, '')}.${this.extension}`;
    } else {
      this.inputTarget.value = `${this.fileName}.${this.extension}`;
    }
    this.checkDuplicateFileName();
  }

  checkDuplicateFileName() {
    this.fileNames = JSON.parse(this.fileNamesTarget.getAttribute('data-value'));

    this.errorMessages = [];
    if (this.fileNames.includes(this.inputTarget.value)) {
      this.errorMessages.push('File name already exists');
    }

    if (!this.inputTarget.value.split('.')[0].replace(/\s?\(.*\)/g, '').match(/^[a-zA-Z0-9_-]+$/)) {
      this.errorMessages.push('Invalid characters for lot assignment');
    }

    this.errorMessage = this.inputTarget.parentNode.querySelector('.invalid-feedback');

    if (this.errorMessages.length > 0) {
      if (this.errorMessage) {
        this.errorMessage.innerText = this.errorMessages.join('\n');
      } else {
        this.inputTarget.classList.add('is-invalid');
        this.errorMessage = document.createElement('div');
        this.errorMessage.classList.add('invalid-feedback');
        this.errorMessage.innerText = this.errorMessages.join('\n');
        this.inputTarget.parentNode.appendChild(this.errorMessage);
      }
    } else {
      this.inputTarget.classList.remove('is-invalid');
      if (this.errorMessage) {
        this.errorMessage.remove();
      }
    }
  }

  submit(event) {
    event.preventDefault();
    const params = { upload_file_name: this.inputTarget.value };
    fetch(event.target.getAttribute('data-url'), {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    }).then(response => response.json())
      .then((data) => {
        if (data.overwrite) {
          window.location.reload();
        } else {
          this.row = document.getElementById(event.target.getAttribute('data-id'));
          this.row.insertAdjacentHTML('beforebegin', data.html);
          this.newRow = this.row.previousElementSibling;
          this.newRow.classList.add('flash-opacity');
          this.row.remove();
        }
      });
  }
}
