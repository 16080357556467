import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['buyerNumber'];

  showModal(event) {
    event.preventDefault();
    let buyerNumber = '';
    if (this.hasBuyerNumberTarget) {
      ({ buyerNumber } = this.buyerNumberTarget.dataset);
    }
    const lotEditModal = this.createModal(this.data.get('id'), this.data.get('price'), buyerNumber);
    document.body.appendChild(lotEditModal);
    $('#lot-edit-modal').modal('show');
  }

  createModal(lot, price, buyerNumber) {
    const modal = document.createElement('div');
    modal.classList.add('modal', 'fade');
    modal.id = 'lot-edit-modal';
    modal.innerHTML = `
      <div class="modal-dialog">
        <form id="edit-lot-${lot}" method="post" novalidate="novalidate">
          <input type="hidden" name="authenticity_token" value="${$('meta[name="csrf-token"]').attr('content')}">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title" id="lotEditModalLabel"><i class="fas fa-edit"></i> Edit Lot</h3>
              <button type="button" class="close" data-dismiss="modal">×</button>
            </div>
            <div class="modal-body">
              <div class="form-inputs">
                <fieldset class="form-group">
                  <input type="hidden" value="${lot}" name="lot[id]" id="lot-id">
                  <div class="form-group row lot-price">
                    <label class="col-4 col-form-label font-weight-bold" for="lot-price">Price</label>
                    <div class="col-8">
                      <input class="form-control" type="number" min="0" name="lot[price]" id="lot-price" value="${price}">
                    </div>
                  </div>
                  <div class="form-group row lot-buyer-number">
                    <label class="col-4 col-form-label font-weight-bold" for="lot-buyer-number">Buyer number</label>
                    <div class="col-8">
                      <input class="form-control" type="text" name="lot[buyer_number]" id="lot-buyer-number" value="${buyerNumber}">
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
              <button type="submit" class="btn btn-primary">Update</button>
            </div>
          </div>
        </form>
      </div>
    `;
    // Ensure modal is properly removed
    $(modal).on('hidden.bs.modal', () => {
      $(modal).remove();
    });
    return modal;
  }
}
