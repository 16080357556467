import { Controller } from 'stimulus';
import { Sortable } from 'sortablejs';

export default class extends Controller {
  connect() {
    this.orderTargets();
    this.createSortable(this);
    this.startObserver();
  }

  createSortable(_this) {
    Sortable.create(_this.element, {
      animation: 100,
      filter: '.btn, .form-control',
      preventOnFilter: false
    });
  }

  orderTargets() {
    const sortables = Array.from(this.element.getElementsByClassName('nested-fields'));
    sortables.sort((a, b) => (a.getElementsByClassName('position-field')[0].value || 999) - (b.getElementsByClassName('position-field')[0].value || 999));
    sortables.forEach(element => element.remove());
    sortables.forEach(element => this.element.append(element));
    this.updatePositions();
  }

  startObserver() {
    const observer = new MutationObserver(() => {
      this.updatePositions();
    });

    observer.observe(this.element, { subtree: true, childList: true });
  }

  updatePositions() {
    Array.from(this.element.getElementsByClassName('position-field')).forEach((element, index) => {
      element.value = index;
    });
  }
}
