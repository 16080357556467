import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    if (document.querySelector('#domain_permission_all_domains').checked) {
      document.querySelector('#domain_permission_domain_id').hidden = true;
    }
  }

  toggleVisibility(event) {
    document.querySelector('#domain_permission_domain_id').hidden = event.target.checked;
  }
}
