import TableController from './table_controller';

export default class extends TableController {
  static targets = ['filterable', 'noElements'];

  updateResults(event) {
    const categories = ['cattle', 'deer', 'equine', 'goats', 'sheep', 'swine', 'webinar'];
    const key = event.currentTarget.dataset.filterKey?.toLowerCase();
    let showingElements = false;

    if (key) {
      this.filterableTargets.forEach((e) => {
        const element = e;

        let visible = null;
        const filterableValue = element.dataset.filterableValue.toLowerCase();
        visible = (key === 'other') ? !categories.includes(filterableValue) : (filterableValue === key);

        if (visible) {
          element.hidden = false;
          showingElements = true;
        } else {
          element.hidden = true;
        }
      });
    } else {
      this.filterableTargets.forEach((e) => {
        const element = e;
        element.hidden = false;
        showingElements = true;
      });
    }

    this.noElementsTargets.forEach((e) => {
      const element = e;
      element.hidden = showingElements;
    });

    this.restripeTable();
  }
}
