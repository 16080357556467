import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'lot', 'videoPlayer', 'image', 'modalImage', 'propertiesAndUpdates', 'videoComingSoon', 'thirdPartyMessage',
    'videoMask', 'facebookLink', 'twitterLink', 'emailLink', 'link', 'shareLotLabel', 'modalLotLabel',
    'modalLotUrl', 'modalEventUrl', 'eventCopiedMsg', 'lotCopiedMsg', 'watch', 'unwatch'
  ];

  connect() {
    this.firstLotNumber = document.querySelector('.lot-row').dataset.lotNumber;
    const analyticsId = this.element.getAttribute('data-analytics-id');
    const userId = this.element.getAttribute('data-user-id');

    ga('create', analyticsId, 'auto');
    if (typeof userId !== 'undefined' && userId != null && userId !== '') {
      ga('set', 'userId', userId);
    }
    ga('send', 'pageview');
    this.preselectLot();
  }

  copyElement(e, msg) {
    const element = e;
    const messageElement = msg;
    element.select();
    element.setSelectionRange(0, 99999);
    document.execCommand('copy');

    messageElement.style.visibility = 'visible';
    messageElement.style.opacity = 1;
    setTimeout(() => {
      messageElement.style.opacity = 0;
      setTimeout(() => { messageElement.style.visibility = 'hidden'; }, 500);
    }, 5000);
  }

  copyEventUrl() {
    this.copyElement(this.modalEventUrlTarget, this.eventCopiedMsgTarget);
  }

  copyLotUrl() {
    this.copyElement(this.modalLotUrlTarget, this.lotCopiedMsgTarget);
  }

  highlightRow(lotRow) {
    this.lotTargets.forEach((e) => {
      const element = e;
      element.classList.remove('active');
    });

    lotRow.classList.add('active');
  }

  displayImage(imageUrl) {
    if (this.imageTarget.src === imageUrl) { return; }

    if (typeof imageUrl !== 'undefined' && imageUrl != null && imageUrl !== '') {
      this.imageTarget.src = imageUrl;
      if (this.hasModalImageTarget) {
        this.modalImageTarget.src = imageUrl;
      }
      this.imageTarget.hidden = false;
    } else {
      this.imageTarget.hidden = true;
      this.imageTarget.removeAttribute('src');
    }
  }

  displayLotProperties(lotId) {
    this.propertiesAndUpdatesTarget.innerHTML = '';

    const xhr = new XMLHttpRequest();
    const controller = this;
    xhr.open('GET', `/video_catalogs/${this.element.getAttribute('data-video-catalog-id')}/lots/${lotId}/lot_properties`);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.onreadystatechange = function loadProperties() {
      controller.propertiesAndUpdatesTarget.innerHTML = xhr.responseText;
    };
    xhr.send();
  }

  loadVideo(videoUrl, posterUrl = null) {
    if (this.videoPlayerTarget.src === videoUrl) { return; }

    this.videoPlayerTarget.pause();

    if (typeof videoUrl !== 'undefined' && videoUrl != null && videoUrl !== '') {
      this.videoPlayerTarget.src = videoUrl;
      this.videoComingSoonTarget.hidden = true;
      if (typeof posterUrl !== 'undefined' && posterUrl != null && posterUrl !== '') {
        this.videoPlayerTarget.poster = posterUrl;
        this.videoMaskTarget.classList.remove('bg-black');
      }
    } else {
      this.videoComingSoonTarget.hidden = false;
      this.videoPlayerTarget.removeAttribute('src');
    }
    this.videoPlayerTarget.load();
  }

  logGoogleAnalytics(lotNumber, lotId) {
    ga('send', 'event', 'Video Catalog', 'Play Video', (`Lot #: ${lotNumber} (ID: ${lotId})`), 1, { nonInteraction: 1 });
  }

  playVideo() {
    // Remove the video mask
    if (this.hasVideoMaskTarget) {
      this.videoMaskTarget.hidden = true;
    }

    const videoSource = this.videoPlayerTarget.src;
    if (typeof (videoSource) === 'undefined' || videoSource == null || videoSource === '') { return; }

    this.videoPlayerTarget.play();

    const lotId = this.element.getAttribute('data-lot-id');
    this.trackVideoPlay(lotId);
  }

  preselectLot() {
    const preselectedLotId = this.element.getAttribute('data-preselected-lot-id');
    let preselectedElement;

    // Find matching lot element
    if (typeof preselectedLotId !== 'undefined' && preselectedLotId != null) {
      for (let i = 0; i < this.lotTargets.length; i += 1) {
        const element = this.lotTargets[i];
        const lotId = element.getAttribute('data-lot-id');

        if (preselectedLotId === lotId) {
          preselectedElement = element;
          break;
        }
      }
    }

    // Default to first lot if preselected element has not been set
    if (typeof (preselectedElement) === 'undefined' || preselectedElement == null) preselectedElement = this.lotTargets[0];

    const videoUrl = preselectedElement.getAttribute('data-video-url');
    if (typeof videoUrl === 'undefined' || videoUrl == null || videoUrl === '') {
      this.videoMaskTarget.hidden = true;
    }

    this.selectLot({ currentTarget: preselectedElement }, false);
    if (preselectedElement.dataset.lotNumber !== this.firstLotNumber) {
      preselectedElement.scrollIntoView();
    }
  }

  trackVideoPlay(lotId) {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', `/video_catalogs/${this.element.getAttribute('data-video-catalog-id')}/track_play?lot_id=${lotId}`, true);
    xhr.setRequestHeader('X-CSRF-Token', document.getElementsByName('csrf-token')[0].content);
    xhr.send();
  }

  updateSharingLinks(number) {
    const url = `https://www.dvauction.com/video_catalogs/${this.element.getAttribute('data-video-catalog-id')}?play=${number}`;
    const encodedUrl = encodeURIComponent(url);
    const title = this.element.getAttribute('data-video-catalog-title');
    const eventTitle = this.element.getAttribute('data-event-title');
    const text = encodeURIComponent(`Lot ${number}: ${title}`);
    this.shareLotLabelTarget.innerHTML = `Share Lot ${number}:`;
    this.facebookLinkTarget.href = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&quote=${text}`;
    this.twitterLinkTarget.href = `https://www.twitter.com/intent/tweet?url=${encodedUrl}&text=${text}`;
    this.emailLinkTarget.href = `mailto:?subject=${encodeURIComponent(`Video for Lot ${number}: ${title} - ${eventTitle}`)}&body=${encodedUrl}`;
    this.linkTarget.href = url;
    this.modalLotLabelTarget.innerHTML = `Video Catalog Link - Lot: ${number}`;
    this.modalLotUrlTarget.value = url;
  }

  facebookPopup(e) {
    const event = e;
    const height = 450;
    const width = 550;
    const top = ((window.innerHeight - height) / 2) + window.screenTop;
    const left = ((window.innerWidth - width) / 2) + window.screenLeft;
    event.preventDefault();

    window.open(
      event.currentTarget.href,
      'Facebook Share Window',
      `height=${height}, width=${width}, top=${top}, left=${left}, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`
    );
  }
}
