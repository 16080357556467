import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['videoPlayer', 'videoUrl'];

  changeVideoUrl(event) {
    event.preventDefault();
    this.videoPlayerTarget.src = event.target.getAttribute('data-url');
    this.videoUrlTargets.forEach((videoUrl) => {
      videoUrl.classList.remove('active');
    });
    event.target.classList.add('active');
    window.scrollTo(0, 0);
  }

  connect() {
    this.videoUrlTarget.classList.add('active');
    this.setVideoPlayerDimensions();

    window.addEventListener('resize', () => {
      this.setVideoPlayerDimensions();
    });
  }

  nextVideo() {
    let nextUrl;
    this.videoUrlTargets.forEach((videoUrl) => {
      videoUrl.classList.remove('active');
      if (videoUrl.getAttribute('data-url') === this.videoPlayerTarget.src) {
        nextUrl = videoUrl.parentElement.nextElementSibling.firstElementChild;
      }
    });
    this.videoPlayerTarget.src = nextUrl.getAttribute('data-url');
    nextUrl.classList.add('active');
  }

  setVideoPlayerDimensions() {
    if (window.innerWidth >= 992) {
      this.videoPlayerTarget.width = window.innerWidth * 0.75;
    } else {
      this.videoPlayerTarget.width = window.innerWidth;
    }
  }
}
