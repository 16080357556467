import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['invoiceTotal', 'invoiceTransactions'];

  updateTotal() {
    let total = 0;
    this.invoiceTransactionsTargets.forEach((invoiceTransaction) => {
      if (!invoiceTransaction.hidden) {
        const type = invoiceTransaction.getElementsByClassName('invoice_invoice_transactions_transaction_type')[0].firstChild.value;
        let amount = Number.parseFloat(invoiceTransaction.getElementsByClassName('invoice_invoice_transactions_amount')[0].firstChild.value);
        if (!amount) {
          amount = 0;
        }
        if (type === 'Debit' || type === 'Charge') {
          total += amount;
        } else {
          total -= amount;
        }
      }
    });
    this.invoiceTotalTarget.innerText = `$${parseFloat(total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  }
}
