import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['activeDisplay', 'markActiveLink', 'markInactiveLink'];

  updateActive(event) {
    event.preventDefault();
    const url = event.currentTarget.getAttribute('data-url');
    fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
      .then((data) => {
        this.activeDisplayTarget.innerText = data.active ? 'Yes' : 'No';
        this.activeDisplayTarget.classList.toggle('table-danger', !data.active);
        this.markActiveLinkTarget.classList.toggle('d-none', data.active);
        this.markInactiveLinkTarget.classList.toggle('d-none', !data.active);
      });
  }
}
