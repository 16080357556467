import UploadsController from './uploads_controller';

export default class extends UploadsController {
  static targets = super.targets.concat(['daySelect', 'dayValue'])

  attach(id) {
    const signedBlobIds = document.querySelectorAll('input[name="event[upload_attachments][]"][type="hidden"]');
    const signedBlobId = signedBlobIds[id - 1];
    const uploadParams = {
      raw_footage_upload: {
        signedBlobId: signedBlobId.value,
        day: this.daySelectTarget.value
      }
    };

    const progress = document.getElementById(`direct-upload-progress-${id}`);
    const progressChildren = progress.children;

    fetch(`${window.location.pathname}/attach`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(uploadParams)
    }).then((response) => {
      if (!response.ok) { throw response; }
      return response.json();
    }).then(() => {
      progressChildren[0].classList.add('bg-success');
    }).catch((error) => {
      this.log(`fetch error (${id}, ${error})`);
      progress.classList.add('bg-danger');
      progressChildren[0].classList.add('bg-danger');
      progressChildren[1].innerHTML = 'Failed';
      this.uploadedFileCount -= 1;
      this.progressContainerTarget.children[0].style.width = `${Math.round(this.uploadedFileCount / this.selectedFileCount * 100)}%`;
      this.progressContainerTarget.children[1].innerHTML = `${this.uploadedFileCount}/${this.selectedFileCount}`;
    });
  }

  buildFileUploadRow(file, id) {
    const tr = document.createElement('tr');
    tr.id = `direct-upload-${id}`;
    tr.classList.add('direct-upload');
    tr.innerHTML = `
        <td class="text-wrap-anywhere direct-upload-file-name">${file.name}</td>
        <td id="direct-upload-size-${id}" class="text-nowrap">${this.toHumanFileSize(file.size, true)}</td>
        <td class="align-middle w-25">
          <div id="direct-upload-progress-${id}" class="progress bg-secondary position-relative">
            <div class="progress-bar"></div>
            <div class="position-absolute progress-value text-light">0%</div>
          </div>
        </td>
        <td class="align-middle text-center" data-target="raw-footage-uploads.removeTableDataWrapper">
          <i class="cursor-pointer fas fa-times text-danger" data-action="click->raw-footage-uploads#remove" data-name="${file.name}"></i>
        </td>
      `;
    this.tableBodyTarget.appendChild(tr);
  }

  dateSelect() {
    if (this.daySelectTarget.value === '') {
      this.addFilesButtonTarget.classList.add('disabled');
      this.addFilesButtonTarget.removeAttribute('for');
    } else {
      this.addFilesButtonTarget.classList.remove('disabled');
      this.addFilesButtonTarget.setAttribute('for', 'upload-file-input');
    }

    if (this.inputTarget.files.length > 0 && this.daySelectTarget.value !== '') {
      this.startUploadsButtonTarget.disabled = false;
    } else {
      this.startUploadsButtonTarget.disabled = true;
    }
    this.dayValueTarget.value = this.daySelectTarget.value;
  }

  select() {
    const selectedFiles = this.inputTarget.files;
    this.addFilesButtonTarget.hidden = true;
    if (this.daySelectTarget.value !== '') {
      this.startUploadsButtonTarget.disabled = false;
    }
    this.uploadedFileCount = 0;
    this.selectedFileCount = selectedFiles.length;
    this.progressTarget.innerHTML = `${this.uploadedFileCount}/${this.selectedFileCount}`;
    this.progressTarget.hidden = false;
    this.selectedFilesCardTarget.hidden = false;
    this.tableBodyTarget.innerHTML = '';
    this.buildFileUploadRows(selectedFiles);
  }

  submit(event) {
    event.target.hidden = true;
    this.removeTableDataWrapperTargets.forEach((tableData) => { tableData.remove(); });
    this.daySelectTarget.disabled = true;
  }
}
