import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['idField', 'labelField'];

  clearRunRep(event) {
    event.preventDefault();
    this.labelFieldTarget.value = 'Select run rep...';
    this.idFieldTarget.value = '';
    $('#run-rep-select-modal').modal('hide');
  }

  selectRunRep(event) {
    const userDataset = event.currentTarget.dataset;
    this.labelFieldTarget.value = userDataset.recordName;
    this.idFieldTarget.value = userDataset.recordId;
    $('#run-rep-select-modal').modal('hide');
  }
}
