import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['eventSearchIdInput', 'idField', 'labelField', 'loadingIcon', 'resultsTable', 'searchError', 'searchForm'];

  connect() {
    $('#lot-search-modal').on('shown.bs.modal', () => {
      document.getElementById('keywords_number').focus();
    });
  }

  getEvents(event) {
    this.resultsTableTarget.innerHTML = '';
    const formData = new FormData();
    formData.set('keywords[domain_id]', event.target.value);
    const searchParams = new URLSearchParams(formData);
    const url = `/admin/search/lots_events_select?${searchParams}`;
    fetch(url)
      .then(response => response.text())
      .then((html) => {
        this.eventSearchIdInputTarget.innerHTML = html;
        this.searchLots(event);
      });
  }

  searchLots(event) {
    event.preventDefault();
    this.searchErrorTarget.hidden = true;
    this.resultsTableTarget.hidden = true;
    const formData = new FormData(this.searchFormTarget);
    const searchParams = new URLSearchParams(formData);
    const url = `/admin/search/lots?${searchParams}`;
    if (searchParams.get('keywords[event_id]').length > 0) {
      this.loadingIconTarget.hidden = false;

      fetch(url)
        .then(response => response.text())
        .then((html) => {
          this.resultsTableTarget.innerHTML = html;
          this.loadingIconTarget.hidden = true;
          this.resultsTableTarget.hidden = false;
        });
    } else {
      this.searchErrorTarget.hidden = false;
    }
  }

  selectLot(event) {
    const eventDataset = event.currentTarget.dataset;
    this.labelFieldTarget.value = eventDataset.recordName;
    this.idFieldTarget.value = eventDataset.recordId;
    $('#lot-search-modal').modal('hide');
  }
}
