import { Controller } from 'stimulus';

class AjaxController extends Controller {
  load(event) {
    const targetContainerId = event.currentTarget.getAttribute('data-container-id');
    const targetContainer = document.getElementById(targetContainerId);
    const forceLoad = (event.currentTarget.getAttribute('data-force-load') === 'true');
    if (typeof targetContainer !== 'undefined' && targetContainer !== null && (targetContainer.innerHTML === '' || forceLoad)) {
      const url = event.currentTarget.getAttribute('data-url');
      const request = new XMLHttpRequest();
      request.open('GET', url, true);

      request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

      request.onload = function loadTabData() {
        if (this.status >= 200 && this.status < 400) {
          targetContainer.innerHTML = this.response;
          dispatchEvent(new Event('localizeTime'));
        }
      };

      request.send();
    }
  }
}

export default AjaxController;
