import { Controller } from 'stimulus';

export default class extends Controller {
  fetch(event) {
    event.preventDefault();
    fetch(this.element.action, {
      method: 'POST',
      headers: { 'X-CSRF-Token': document.querySelector("[name='csrf-token']").content },
      body: new URLSearchParams(new FormData(this.element))
    }).then(response => response.text())
      .then((html) => {
        $(this.element.closest('.modal')).modal('hide');
        this.target = document.getElementById(this.data.get('targetId'));
        this.target.insertAdjacentHTML('beforebegin', html);
        this.newTarget = this.target.previousElementSibling;
        this.newTarget.classList.add('flash-opacity');
        this.target.remove();
      });
  }
}
