import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['lot', 'description', 'amount', 'priceCorrectionModal', 'priceCorrectionInput'];

  updateRow(event) {
    const selectedLot = event.currentTarget[event.currentTarget.selectedIndex];
    const lotId = selectedLot.value;

    if (typeof lotId !== 'undefined' && lotId != null && lotId !== '') {
      this.descriptionTarget.value = selectedLot.getAttribute('data-description');

      const price = selectedLot.getAttribute('data-price');
      if (typeof price !== 'undefined' && price != null && price !== '') {
        const priceRegexp = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;
        if (priceRegexp.exec(price) !== null) {
          this.amountTarget.value = Number.parseFloat(price).toFixed(2);
        } else {
          this.amountTarget.value = '';
          this.element.appendChild(this.createModal(price));
          $(this.priceCorrectionModalTarget).modal('show');
        }
      } else {
        this.amountTarget.value = '';
      }

      const invoiced = (selectedLot.getAttribute('data-invoiced') === 'true');
      if (invoiced) {
        $('#duplicate-transaction-modal').modal('show');
      }
    } else {
      this.descriptionTarget.value = '';
      this.amountTarget.value = '';
    }
    this.amountTarget.dispatchEvent(new Event('change'));
  }

  updatePrice() {
    this.amountTarget.value = Number.parseFloat(this.priceCorrectionInputTarget.value).toFixed(2);
    this.amountTarget.dispatchEvent(new Event('change'));
    const $modal = $(this.priceCorrectionModalTarget);
    $modal.modal('hide');
  }

  createModal(value) {
    const modal = document.createElement('div');
    modal.classList.add('modal', 'fade');
    modal.dataset.target = `${this.identifier}.priceCorrectionModal`;
    modal.innerHTML = `<div class="modal-dialog modal-dialog-centered">
                         <div class="modal-content">
                           <div  class="modal-header">
                             <h3 class="modal-title text-danger"><i class="fas fa-exclamation-triangle"></i> Price Correction Required</h3>
                           </div>
                           <div class="modal-body py-3">
                             <p class="text-xs text-danger mb-3">
                               The price for the selected lot was unable to be inserted automatically.
                               Please correct the following information:
                            </p>
                            <form>
                              <div class="form-group row">
                                <label class="col-4 col-form-label font-weight-bold">Invalid Price</label>
                                <div class="col-8">
                                  <input type="text" readonly class="form-control-plaintext" value="${value}">
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-4 col-form-label font-weight-bold">Corrected Price</label>
                                <div class="col-8">
                                  <input type="number" min="0" class="form-control numeric decimal required" step="any" data-target="${this.identifier}.priceCorrectionInput">
                                </div>
                              </div>
                            </form>
                           </div>
                           <div class="modal-footer">
                            <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
                            <input data-action="click->${this.identifier}#updatePrice" type="button" class="btn btn-primary" value="Submit">
                           </div>
                        </div>
                      </div>`;
    // Ensure modal is properly removed
    $(modal).on('hidden.bs.modal', () => {
      $(modal).remove();
    });
    return modal;
  }
}
