import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['links', 'template', 'invoiceTransactions', 'noInvoiceTransactionsHeader', 'invoiceTransactionsHeader'];

  connect() {
    this.wrapperClass = this.data.get('wrapperClass') || 'nested-fields';
    if (this.hasInvoiceTransactionsTarget) {
      this.invoiceTransactionsHeaderTarget.hidden = false;
    } else {
      this.noInvoiceTransactionsHeaderTarget.hidden = false;
    }
  }

  addAssociation() {
    this.invoiceTransactionsHeaderTarget.hidden = false;
    this.noInvoiceTransactionsHeaderTarget.hidden = true;
    const content = this.templateTarget.innerHTML.replace(/new_record/g, new Date().getTime());
    this.linksTarget.insertAdjacentHTML('beforebegin', content);
  }

  removeAssociation(event) {
    const wrapper = event.target.closest(`.${this.wrapperClass}`);

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove();
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.hidden = true;
    }
  }
}
