import Rails from '@rails/ujs';
import UploadsController from '../uploads_controller';

export default class extends UploadsController {
  static targets = ['addToSaleOrderWrapper', 'createLotsWrapper', 'filenameWarningModal', 'form', 'generateCatalogVideosWrapper',
    'sortLotsWrapper', 'stripAudioWrapper', 'videoDisplayTypeWrapper']

  attach(id) {
    const signedBlobIds = document.querySelectorAll('input[name="upload[upload_attachments][]"][type="hidden"]');
    const signedBlobId = signedBlobIds[id - 1];
    const uploadParams = {
      upload: {
        signedBlobId: signedBlobId.value,
        strip_audio: this.stripAudioWrapperTarget.querySelector('input[type="checkbox"]').checked
      }
    };

    if (this.hasVideoDisplayTypeWrapperTarget) {
      uploadParams.upload.video_display_type = this.videoDisplayTypeWrapperTarget.querySelector('input[type="checkbox"]').checked;
    }

    const progress = document.getElementById(`direct-upload-progress-${id}`);
    const progressChildren = progress.children;

    fetch('attach', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(uploadParams)
    }).then((response) => {
      if (!response.ok) { throw response; }
      return response.json();
    }).then(() => {
      progressChildren[0].classList.add('bg-success');
    }).catch((error) => {
      this.log(`fetch error (${id}, ${error})`);
      progress.classList.add('bg-danger');
      progressChildren[0].classList.add('bg-danger');
      progressChildren[1].innerHTML = 'Failed';
      this.uploadedFileCount -= 1;
      this.progressContainerTarget.children[0].style.width = `${Math.round(this.uploadedFileCount / this.selectedFileCount * 100)}%`;
      this.progressContainerTarget.children[1].innerHTML = `${this.uploadedFileCount}/${this.selectedFileCount}`;
    });
  }

  buildFileUploadRow(file, id) {
    const tr = document.createElement('tr');
    tr.id = `direct-upload-${id}`;
    tr.classList.add('direct-upload');
    if (this.hasFilenameWarningModalTarget && file.name.toLowerCase().startsWith('lot')) {
      $(this.filenameWarningModalTarget).modal('show');
    }
    tr.innerHTML = `
        <td class="text-wrap-anywhere direct-upload-file-name">${file.name}</td>
        <td id="direct-upload-size-${id}" class="text-nowrap">${this.toHumanFileSize(file.size, true)}</td>
        <td class="align-middle w-25">
          <div id="direct-upload-progress-${id}" class="progress bg-secondary position-relative">
            <div class="progress-bar"></div>
            <div class="position-absolute progress-value text-light">0%</div>
          </div>
        </td>
        <td class="align-middle text-center" data-target="admin--video-uploads.removeTableDataWrapper">
          <i class="cursor-pointer fas fa-times text-danger" data-action="click->admin--video-uploads#remove" data-name="${file.name}"></i>
        </td>
      `;
    this.tableBodyTarget.appendChild(tr);
  }

  createLotsParams() {
    const addToSaleOrder = this.addToSaleOrderWrapperTarget.querySelector('input[type="checkbox"]').checked;
    const sortLots = this.sortLotsWrapperTarget.querySelector('input[type="checkbox"]').checked;
    const videoDisplayType = this.videoDisplayTypeWrapperTarget.querySelector('input[type="checkbox"]').checked;
    const fileNames = [];
    document.querySelectorAll('.direct-upload-file-name').forEach((element) => {
      fileNames.push(element.innerHTML);
    });

    return {
      add_to_sale_order: addToSaleOrder,
      sort_lots: sortLots,
      video_display_type: videoDisplayType,
      file_names: fileNames
    };
  }

  updateLotCheckboxes() {
    const createLots = this.createLotsWrapperTarget.querySelector('input[type="checkbox"]').checked;
    const addToSaleOrder = (createLots && this.addToSaleOrderWrapperTarget.querySelector('input[type="checkbox"]').checked);

    this.addToSaleOrderWrapperTarget.hidden = !createLots;
    this.sortLotsWrapperTarget.hidden = !addToSaleOrder;
  }

  select() {
    this.log('select');

    const selectedFiles = Array.from(this.inputTarget.files).filter(file => file.type.match(/video.*/));
    const invalidSelectedFiles = Array.from(this.inputTarget.files).filter(file => !file.type.match(/video.*/));
    if (invalidSelectedFiles.length !== 0) {
      this.alertMessage(`<span class="font-weight-bold">Invalid files removed:</span> ${invalidSelectedFiles.map(file => file.name).join(', ')}`);
    } else {
      this.alertMessage('', true);
    }
    const dataTransfer = new DataTransfer();
    selectedFiles.forEach((file) => { dataTransfer.items.add(file); });
    this.inputTarget.files = dataTransfer.files;
    this.addFilesButtonTarget.hidden = true;
    this.startUploadsButtonTarget.disabled = false;
    this.uploadedFileCount = 0;
    this.selectedFileCount = selectedFiles.length;
    this.progressTarget.innerHTML = `${this.uploadedFileCount}/${this.selectedFileCount}`;
    this.progressTarget.hidden = false;
    this.selectedFilesCardTarget.hidden = false;
    this.tableBodyTarget.innerHTML = '';
    this.buildFileUploadRows(selectedFiles);
    // this.checkUploadDuplicates(); TODO (Rod Hunsaker): Optimize check
  }

  submit(event) {
    this.log('submit');
    event.target.hidden = true;
    let createLots = false;
    if (this.hasCreateLotsWrapperTarget) {
      createLots = this.createLotsWrapperTarget.querySelector('input[type="checkbox"]').checked;
    }

    if (createLots) {
      fetch('create_lots', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.createLotsParams())
      }).then(() => {
        // Begin upload once lots are successfully created
        Rails.fire(this.formTarget, 'submit');
      }).catch((error) => {
        this.log(`fetch error (${error})`);
      });
    } else {
      Rails.fire(this.formTarget, 'submit');
    }
    this.removeTableDataWrapperTargets.forEach((tableDataWrapper) => { tableDataWrapper.remove(); });
  }
}
