import { Controller } from 'stimulus';
import { localizeDateTime } from './datetime_localization_controller';
import consumer from '../channels/consumer';

let pageChange;
let timeRemaining;

window.addEventListener('beforeunload', () => {
  pageChange = true;
});

export default class extends Controller {
  static targets = ['countdown', 'currentBid', 'endsAt', 'fixedStatusDisconnected', 'highBidderMsg', 'lastUpdate', 'maxBidMsg', 'minBidMsg', 'minimumBid',
    'statusConnected', 'statusDisconnected', 'youAreOutMsg'];

  connect() {
    console.log(`[CONTROLLER][CONNECT] subscribe: ${this.element.dataset.subscribe}`);
    if (this.element.dataset.subscribe !== 'true') return;

    setInterval(() => {
      if (typeof timeRemaining !== 'undefined' && timeRemaining != null && timeRemaining !== '') {
        console.log(`Time remaining: ${timeRemaining}`);
        timeRemaining -= 1;
        this.updateCountdownElements();
      }
    }, 1000);

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        pageChange = false;
        this.subscription.consumer.connect();
      } else {
        pageChange = true;
        timeRemaining = '';
        this.subscription.consumer.disconnect();
      }
    });

    this.createSubscription();
  }

  createSubscription() {
    const lotShowController = this;
    this.subscription = consumer.subscriptions.create({ channel: 'EventChannel', id: this.element.dataset.eventId }, {
      connected() {
        lotShowController.fixedStatusDisconnectedTarget.hidden = true;
        lotShowController.statusDisconnectedTarget.hidden = true;
        lotShowController.statusConnectedTarget.hidden = false;
      },

      received(data) {
        console.log('[CABLE][RECEIVED]');
        console.dir(data);

        // Check for page refresh
        if (data.refresh?.toString() === 'true' && (!data.lot_id || lotShowController.element.dataset.lotId === data.lot_id.toString())) {
          console.log('[CABLE][RECEIVED][REFRESH]');
          window.location.reload();
          return;
        }

        // Handle timeRemaining updates
        if (data.time_remaining) {
          console.log(`[CABLE][RECEIVED][TIME REMAINING] time_remaining: ${data.time_remaining}`);
          timeRemaining = parseInt(data.time_remaining, 10);

          // Handle endsAt updates
          if (data.ends_at) {
            console.log(`[CABLE][RECEIVED][ENDS AT] ends_at: ${data.ends_at}`);
            const formattedDateTime = localizeDateTime(data.ends_at, (timeRemaining < 18000) ? 'time' : 'default');
            const localizedEndsAtHTML = `${formattedDateTime.date || ''} ${formattedDateTime.time || ''}`;
            lotShowController.endsAtTarget.innerHTML = localizedEndsAtHTML;
          }
        }

        // Handle single lot updates
        console.log(`lot_id: ${data.lot_id}`);
        console.log(`lotShowController.element.dataset.lotId: ${lotShowController.element.dataset.lotId}`);
        if (data.lot_id && lotShowController.element.dataset.lotId === data.lot_id.toString()) {
          console.log(`[CABLE][RECEIVED][LOT] lot_id: ${data.lot_id}, current_bid: ${data.current_bid}, high_bidder: ${data.high_bidder}
                       minimum_bid: ${data.minimum_bid}, formatted_minimum_bid: ${data.formatted_minimum_bid}`);
          lotShowController.updateLot(data.current_bid, data.minimum_bid, data.formatted_minimum_bid, data.high_bidder);
        }

        // Handle lastUpdate updates
        if (data.last_update && lotShowController.element.dataset.lotId === data.lot_id.toString()) {
          console.log(`[CABLE][RECEIVED][LAST UPDATE] last_update: ${data.last_update}`);
          const formattedDateTime = localizeDateTime(data.last_update, 'default_with_seconds');
          const localizedLastUpdateHTML = `${formattedDateTime.date || ''} ${formattedDateTime.time || ''}`;
          lotShowController.lastUpdateTarget.innerHTML = localizedLastUpdateHTML;
        }
      },

      disconnected() {
        console.log('[CABLE][DISCONNECTED]');
        if (pageChange !== true) {
          lotShowController.fixedStatusDisconnectedTarget.hidden = false;
          lotShowController.statusDisconnectedTarget.hidden = false;
          lotShowController.statusConnectedTarget.hidden = true;
        }
      }
    });
  }

  disconnect() {
    this.subscription.unsubscribe();
  }

  updateCountdownElements() {
    const countdownHTML = this.countdownHTML();

    if (countdownHTML && this.hasCountdownTarget) {
      this.countdownTarget.innerHTML = countdownHTML;
    }
  }

  countdownHTML() {
    const hours = Math.floor(timeRemaining / 3600);

    if (hours > 23) return null;
    if (timeRemaining < 0) return ' ';

    const minutes = Math.floor((timeRemaining - (hours * 3600)) / 60);
    const seconds = Math.floor(timeRemaining - (hours * 3600) - (minutes * 60));

    let result = '...in ';
    if (timeRemaining < 60) { // Less than 60 seconds
      result += `<span class='text-danger font-weight-bold'>${seconds}s</span>`;
    } else if (timeRemaining < 300) { // Less than 5 minutes
      result += `${minutes}m`;
      if (seconds > 0) result += ` ${seconds}s`;
    } else if (timeRemaining < 3600) { // Less than 1 hour
      result += `${minutes}m`;
    } else if (timeRemaining <= 86400) { // 24 hours or less
      result += `${hours}h`;
      if (minutes > 0) result += ` ${minutes}m`;
    } else if (minutes > 30) {
      result += `about ${hours + 1} hours`;
    } else {
      result += `about ${hours} hours`;
    }

    return `${result}`;
  }

  updateLot(currentBid, minimumBid, formattedMinimumBid, highBidder) {
    this.currentBidTarget.innerHTML = `Current Bid: ${currentBid}`;
    console.log(`[CABLE][RECEIVED][LOT][UPDATE LOT] User maximum bid: ${this.element.dataset.maximumBid}`);
    const maximumBid = this.element.dataset.maximumBid ? parseInt(this.element.dataset.maximumBid, 10) : 0;
    if (parseInt(minimumBid, 10) > maximumBid) {
      this.minimumBidTargets.forEach((element) => { element.innerHTML = formattedMinimumBid; });
      if (this.hasMaxBidMsgTarget) this.maxBidMsgTarget.hidden = true;
      if (this.hasMinBidMsgTarget) this.minBidMsgTarget.hidden = false;
    }

    if (this.hasHighBidderMsgTarget && this.hasYouAreOutMsgTarget) {
      if (highBidder.toString() === 'true') {
        console.log('[CABLE][RECEIVED][LOT][HIGH BIDDER][IN]');
        this.youAreOutMsgTarget.hidden = true;
        this.highBidderMsgTarget.hidden = false;
      } else {
        console.log('[CABLE][RECEIVED][LOT][HIGH BIDDER][OUT]');
        if (this.highBidderMsgTarget.hidden === false) {
          $('#outbid-modal').modal('show');
        }
        this.highBidderMsgTarget.hidden = true;
        this.youAreOutMsgTarget.hidden = false;
        // eslint-disable-next-line no-unused-expressions
        setTimeout(() => { document.querySelector('.alert-success button.close')?.click(); }, 5000);
      }
    }
  }
}
