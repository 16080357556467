import { Controller } from 'stimulus';

class LightboxController extends Controller {
  static targets = ['image'];

  loadImage(event) {
    if (event.target.getAttribute('data-image-override')) {
      this.imageTarget.src = event.target.getAttribute('data-image-override');
    } else {
      this.imageTarget.src = event.target.src;
    }
  }
}

export default LightboxController;
