import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['message', 'value'];

  copyValue() {
    this.messageTarget.hidden = false;
    navigator.clipboard.writeText(this.valueTarget.value);
    setTimeout(() => { this.messageTarget.hidden = true; }, 5000);
  }
}
