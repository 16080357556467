import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container', 'select'];

  connect() {
    if (this.hasSelectTarget) {
      this.selectTarget.addEventListener('change', (event) => { this.updateProperties(event.target.options[event.target.selectedIndex].value); });
    }
  }

  updateProperties(id) {
    if (id !== '') {
      this.url = `${this.data.get('url')}?property_template_id=${id}`;
      fetch(this.url, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (!response.ok) { throw response; }
        return response.text();
      }).then((data) => {
        this.containerTarget.insertAdjacentHTML('beforeend', data);
      }).catch(((error) => {
        this.containerTarget.innerHTML = error.statusText;
      }));
    }
  }
}
