import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'dateTimeModal', 'dateTimeModalTitle', 'endsAtInput',
    'extensionTimeInput', 'extensionTimeDropdown',
    'modal', 'modalTitle', 'startsAtInput', 'tr'
  ];

  connect() {
    $(this.modalTarget).on('shown.bs.modal', () => {
      this.input = this.modalTarget.querySelector('input');
      if (this.input !== null) {
        this.input.value = '';
        this.input.focus();
      }
    });

    this.endsAtInputTarget.addEventListener('change', () => { this.updateTimes('ends_at', this.endsAtInputTarget.value); });
    this.startsAtInputTarget.addEventListener('change', () => { this.updateTimes('starts_at', this.startsAtInputTarget.value); });

    this.element.addEventListener('keypress', (event) => {
      if (event.target.type !== 'select-one' && event.key === 'Enter') { event.preventDefault(); }
    });

    if (this.hasExtensionTimeInputTarget) {
      this.extensionTimeInputTargets.forEach((input) => {
        if (input.value === '0') { this.hasZeroInput = true; }
        if (input.value === '5') { this.hasFiveInput = true; }
      });
      if (this.hasExtensionTimeDropdownTarget) {
        if (this.hasZeroInput && this.hasFiveInput) {
          this.extensionTimeDropdownTarget.selected = '';
        } else {
          this.extensionTimeDropdownTarget.options[0].remove();
          this.extensionTimeDropdownTarget.value = this.extensionTimeInputTarget.value;
        }
      }
    }

    this.element.addEventListener('keydown', (event) => {
      if (event.key === 'ArrowUp') {
        event.preventDefault();
        this.columnIndex = event.target.closest('td').cellIndex;
        this.rowIndex = event.target.closest('tr').rowIndex - 2;
        this.focusTarget = event.target.closest('tbody').querySelectorAll('tr')[this.rowIndex];
        if (this.focusTarget !== undefined) {
          this.td = this.focusTarget.children[this.columnIndex];
          this.focusTarget = this.td.querySelector('input:not([type="hidden"]');
          if (this.focusTarget === null) { this.focusTarget = this.td.querySelector('select'); }
          this.focusTarget.focus();
          if (this.focusTarget.type === 'text' || this.focusTarget.type === 'number') { this.focusTarget.select(); }
        }
      }

      if (event.key === 'ArrowDown') {
        event.preventDefault();
        this.columnIndex = event.target.closest('td').cellIndex;
        this.rowIndex = event.target.closest('tr').rowIndex;
        this.focusTarget = event.target.closest('tbody').querySelectorAll('tr')[this.rowIndex];
        if (this.focusTarget !== undefined) {
          this.td = this.focusTarget.children[this.columnIndex];
          this.focusTarget = this.td.querySelector('input:not([type="hidden"]');
          if (this.focusTarget === null) { this.focusTarget = this.td.querySelector('select'); }
          this.focusTarget.focus();
          if (this.focusTarget.type === 'text' || this.focusTarget.type === 'number') { this.focusTarget.select(); }
        }
      }

      if (event.key === 'ArrowLeft') {
        event.preventDefault();
        this.focusTarget = event.target.parentNode.previousElementSibling;
        if (this.focusTarget !== null) {
          this.focusTarget = this.focusTarget.querySelector('input:not([type="hidden"]');
          if (this.focusTarget === null) { this.focusTarget = event.target.parentNode.previousElementSibling.querySelector('select'); }
          this.focusTarget.focus();
          if (this.focusTarget.type === 'text' || this.focusTarget.type === 'number') { this.focusTarget.select(); }
        }
      }

      if (event.key === 'ArrowRight') {
        event.preventDefault();
        this.focusTarget = event.target.parentNode.nextElementSibling;
        if (this.focusTarget !== null) {
          this.focusTarget = this.focusTarget.querySelector('input:not([type="hidden"]');
          if (this.focusTarget === null) { this.focusTarget = event.target.parentNode.nextElementSibling.querySelector('select'); }
          this.focusTarget.focus();
          if (this.focusTarget.type === 'text' || this.focusTarget.type === 'number') { this.focusTarget.select(); }
        }
      }
    });
  }

  openModal(event) {
    this.dataTarget = event.target.getAttribute('data-value');
    this.dataInput = document.querySelector(`[name*=${this.dataTarget}]`).parentNode.cloneNode(true);
    this.dataInput.firstElementChild.classList.add('w-100');
    this.trTarget.innerHTML = this.dataInput.outerHTML;
    this.modalTitleTarget.innerHTML = `Quick Fill ${event.target.parentNode.firstChild.textContent}`;
    $(this.modalTarget).modal('show');
  }

  updateExtensionTimes(event) {
    if (this.hasExtensionTimeInputTarget) {
      this.extensionTimeInputTargets.forEach((input) => {
        input.value = event.target.value;
      });
    }
  }

  updateTimes(field, value) {
    document.querySelectorAll(`[name*="[${field}]"]`).forEach((input) => { input.value = value; });
  }

  updateValues() {
    this.quickFillInput = this.trTarget.querySelector('input:not([type="hidden"]), textarea');
    if (this.quickFillInput === null) { this.quickFillInput = this.trTarget.querySelector('select'); }
    if (this.quickFillInput.type === 'checkbox') {
      document.querySelectorAll(`[name*=${this.dataTarget}]:not([type="hidden"])`).forEach((input) => { input.checked = this.quickFillInput.checked; });
    } else {
      document.querySelectorAll(`[name*=${this.dataTarget}]`).forEach((input) => { input.value = this.quickFillInput.value; });
    }
    $(this.modalTarget).modal('hide');
  }
}
