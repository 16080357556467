import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['idField', 'labelField', 'loadingIcon', 'resultsTable', 'searchError', 'searchForm', 'upcomingEventsTable'];

  connect() {
    $('#event-search-modal').on('shown.bs.modal', () => {
      document.getElementById('keywords_name').focus();
    });
  }

  clearEvent(event) {
    event.preventDefault();
    this.labelFieldTarget.value = 'Select event...';
    this.idFieldTarget.value = '';
    $('#event-search-modal').modal('hide');
  }

  searchEvents(event) {
    event.preventDefault();
    this.searchErrorTarget.hidden = true;
    this.resultsTableTarget.hidden = true;
    const formData = new FormData(this.searchFormTarget);
    const searchParams = new URLSearchParams(formData);
    const url = `/admin/search/events?${searchParams}`;
    let invalidParams = false;
    searchParams.forEach((value, key) => {
      if (['keywords[date_range]', 'keywords[domain_id]', 'keywords[event_type]', 'keywords[state]'].includes(key)) return;

      if (value.length > 0 && value.length < 3) invalidParams = true;
    });
    if (invalidParams) {
      this.searchErrorTarget.hidden = false;
    } else {
      this.loadingIconTarget.hidden = false;

      fetch(url)
        .then(response => response.text())
        .then((html) => {
          this.resultsTableTarget.innerHTML = html;
          this.loadingIconTarget.hidden = true;
          this.upcomingEventsTableTarget.hidden = true;
          this.resultsTableTarget.hidden = false;
        });
    }
  }

  selectEvent(event) {
    const eventDataset = event.currentTarget.dataset;
    this.labelFieldTarget.value = eventDataset.recordName;
    this.idFieldTarget.value = eventDataset.recordId;
    $('#event-search-modal').modal('hide');
    const changeEvent = new Event('change');
    this.idFieldTarget.dispatchEvent(changeEvent);
  }
}
