import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'startUploadsButton', 'cancelUploadsButton', 'totalUploadContainer', 'totalUploadSize', 'totalUploadSizeProgress', 'removeTableDataWrapper',
    'addFilesButton', 'alertMessage', 'status', 'progress', 'progressContainer', 'input', 'selectedFilesCard', 'tableBody'
  ];

  alertMessage(message, hidden = false) {
    this.alertMessageTarget.innerHTML = message;
    this.alertMessageTarget.hidden = hidden;
  }

  attach(id) {
    const signedBlobIds = document.querySelectorAll('input[name="event[upload_attachments][]"][type="hidden"]');
    const signedBlobId = signedBlobIds[id - 1];
    const uploadData = { upload: { signedBlobId: signedBlobId.value } };
    const progress = document.getElementById(`direct-upload-progress-${id}`);
    const progressChildren = progress.children;

    fetch('attach', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(uploadData)
    }).then((response) => {
      if (!response.ok) { throw response; }
      return response.json();
    }).then(() => {
      this.log(`fetch success (${id})`);
      progressChildren[0].classList.add('bg-success');
    }).catch((error) => {
      console.this.log(`fetch error (${id})`, error);
      progress.classList.add('bg-danger');
      progressChildren[0].classList.add('bg-danger');
      progressChildren[1].innerHTML = 'Failed';
      this.uploadedFileCount -= 1;
      this.progressContainerTarget.children[0].style.width = `${Math.round(this.uploadedFileCount / this.selectedFileCount * 100)}%`;
      this.progressContainerTarget.children[1].innerHTML = `${this.uploadedFileCount}/${this.selectedFileCount}`;
    });
  }

  buildFileUploadRow(file, id) {
    const tr = document.createElement('tr');
    tr.id = `direct-upload-${id}`;
    tr.classList.add('direct-upload');
    tr.innerHTML = `
        <td class="text-wrap-anywhere direct-upload-file-name">${file.name}</td>
        <td id="direct-upload-size-${id}" class="text-nowrap">${this.toHumanFileSize(file.size, true)}</td>
        <td class="align-middle w-25">
          <div id="direct-upload-progress-${id}" class="progress bg-secondary position-relative">
            <div class="progress-bar"></div>
            <div class="position-absolute progress-value text-light">0%</div>
          </div>
        </td>
        <td class="align-middle text-center" data-target="uploads.removeTableDataWrapper">
          <i class="cursor-pointer fas fa-times text-danger" data-action="click->uploads#remove" data-name="${file.name}"></i>
        </td>
      `;
    this.tableBodyTarget.appendChild(tr);
  }

  buildFileUploadRows(files) {
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      const id = i + 1;
      this.buildFileUploadRow(file, id);
      this.totalUploadSize += file.size;
      this.totalUploadSizeTarget.innerHTML = this.toHumanFileSize(this.totalUploadSize, true);
    }
  }

  checkUploadDuplicates() {
    this.fileNames = [];
    document.querySelectorAll('.direct-upload-file-name').forEach((element) => { this.fileNames.push(element.innerHTML); });
    if (this.fileNames.length === 0) { return; }

    this.url = new URL(`${window.location.href.replace('/upload', '')}/check_upload_duplicates`);
    this.params = this.fileNames.map(fileName => ['file_names[]', fileName]);
    this.url.search = new URLSearchParams(this.params).toString();
    fetch(this.url, {
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (!response.ok) { throw response; }
      return response.json();
    }).then((data) => {
      if (data.duplicates.length > 0) {
        this.alertMessage(`Overwriting existing files: ${data.duplicates.join(', ')}`);
      }
    }).catch((error) => {
      this.alertMessage(`Error: ${error}`, false);
    });
  }

  toHumanFileSize(bytes) {
    const threshold = 1000;
    const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    if (Math.abs(bytes) < threshold) return `${bytes} B`;

    let result = bytes;
    let i = -1;

    do {
      result /= threshold;
      i += 1;
    } while (Math.abs(result) >= threshold && i < units.length - 1);

    return `${result.toFixed(1)} ${units[i]}`;
  }

  log(message) {
    console.log(`${new Date().getTime()}: ${message}`);
  }

  remove(event) {
    const selectedFiles = Array.from(this.inputTarget.files).filter(file => file.name !== event.target.dataset.name);
    const dataTransfer = new DataTransfer();
    selectedFiles.forEach((file) => { dataTransfer.items.add(file); });
    this.inputTarget.files = dataTransfer.files;
    this.select();
    if (selectedFiles.length < 1) {
      this.addFilesButtonTarget.hidden = false;
      this.startUploadsButtonTarget.disabled = true;
    }
  }

  submit(event) {
    this.log('submit');
    event.target.hidden = true;
    this.removeTableDataWrapperTargets.forEach((tableData) => { tableData.remove(); });
  }

  select() {
    this.log('select');

    const selectedFiles = this.inputTarget.files;
    this.addFilesButtonTarget.hidden = true;
    this.startUploadsButtonTarget.disabled = false;
    this.uploadedFileCount = 0;
    this.selectedFileCount = selectedFiles.length;
    this.progressTarget.innerHTML = `${this.uploadedFileCount}/${this.selectedFileCount}`;
    this.progressTarget.hidden = false;
    this.selectedFilesCardTarget.hidden = false;
    this.tableBodyTarget.innerHTML = '';
    this.buildFileUploadRows(selectedFiles);
    // this.checkUploadDuplicates(); TODO (Rod Hunsaker): Optimize check
  }

  // *** Event Listeners ***

  connect() {
    this.log('connect');
    this.totalUploadSize = 0;
    this.totalUploadSizeProgress = 0;
    this.dropListener();
  }

  dropListener() {
    const card = this.element.querySelector('.card');
    window.addEventListener('dragover', (event) => {
      event.preventDefault();

      // Check if status is not uploading/complete
      if (this.statusTarget.innerHTML === '') { card.classList.add('bg-light', 'border-primary'); }
    });

    window.addEventListener('dragleave', () => {
      card.classList.remove('bg-light', 'border-primary');
    });

    window.addEventListener('drop', (event) => {
      event.preventDefault();

      // Check if status is not uploading/complete
      if (this.statusTarget.innerHTML === '') {
        card.classList.remove('bg-light', 'border-primary');
        const dataTransfer = new DataTransfer();
        const files = Array.from(this.inputTarget.files);
        const newFiles = Array.from(event.dataTransfer.files).filter(newFile => !files.map(file => file.name).includes(newFile.name));

        files.concat(newFiles).sort((file1, file2) => file1.name.localeCompare(file2.name)).forEach((file) => { dataTransfer.items.add(file); });
        this.inputTarget.files = dataTransfer.files;
        this.select();
      }
    });
  }

  startAll() {
    this.log('startAll');
    this.statusTarget.innerHTML = 'Uploading...';
    this.progressContainerTarget.hidden = false;
    if (this.hasTotalUploadContainerTarget) {
      this.totalUploadContainerTarget.hidden = false;
    }
    this.cancelUploadsButtonTarget.hidden = false;
  }

  initializeFile({ detail: { id } }) {
    this.log(`initializeFile (${id})`);
  }

  start({ detail: { id } }) {
    this.log(`start (${id})`);
  }

  beforeBlobRequest(event) {
    this.log('beforeBlobRequest');
    console.dir(event);
  }

  beforeStorageRequest(event) {
    this.log('beforeStorageRequest');
    console.dir(event);
  }

  progress({ detail: { file, id, progress } }) {
    this.log(`progress (${id}, ${Math.round(progress)}%)`);
    const progressSize = file.size * progress / 100;
    const progressDisplay = document.getElementById(`direct-upload-progress-${id}`);
    if (progressDisplay !== null) {
      const progressChildren = document.getElementById(`direct-upload-progress-${id}`).children;
      const progressPercentage = `${Math.round(progress)}%`;
      progressChildren[0].style.width = progressPercentage;
      progressChildren[1].innerHTML = progressPercentage;
      document.getElementById(`direct-upload-size-${id}`).innerHTML = `${this.toHumanFileSize(progressSize, true)}/${this.toHumanFileSize(file.size, true)}`;
    }

    if (this.hasTotalUploadSizeProgressTarget) {
      this.totalUploadSizeProgressTarget.innerHTML = `
        ${this.toHumanFileSize(this.totalUploadSizeProgress + progressSize, true)}/${this.toHumanFileSize(this.totalUploadSize, true)}
      `;
    }
  }

  error(event) {
    this.log(`error (${event})`);
    event.preventDefault();
  }

  end({ detail: { file, id } }) {
    this.log(`end (${id})`);
    if (document.getElementById(`direct-upload-progress-${id}`).children[1].innerHTML === '100%') {
      this.uploadedFileCount += 1;
      this.progressContainerTarget.children[0].style.width = `${Math.round(this.uploadedFileCount / this.selectedFileCount * 100)}%`;
      this.progressContainerTarget.children[1].innerHTML = `${this.uploadedFileCount}/${this.selectedFileCount}`;
      this.totalUploadSizeProgress += file.size;
      this.humanTotalUploadSizeProgress = this.toHumanFileSize(this.totalUploadSizeProgress, true);
      this.humanTotalUploadSize = this.toHumanFileSize(this.totalUploadSize, true);
      this.totalUploadSizeProgressTarget.innerHTML = `${this.humanTotalUploadSizeProgress}/${this.humanTotalUploadSize}`;
    }
    this.attach(id);
  }

  endAll() {
    this.log('endAll');
    if (this.uploadedFileCount === this.selectedFileCount) {
      this.statusTarget.innerHTML = 'Upload(s) Complete';
      this.statusTarget.classList.add('text-success');
      this.progressContainerTarget.children[0].classList.add('bg-success');
    } else if (this.uploadedFileCount === 0) {
      this.statusTarget.innerHTML = 'Upload(s) Failed';
      this.statusTarget.classList.add('text-danger');
      this.progressContainerTarget.children[0].classList.add('bg-danger');
    } else {
      this.statusTarget.innerHTML = 'Upload(s) Incomplete';
      this.statusTarget.classList.add('text-warning');
      this.progressContainerTarget.children[0].classList.add('bg-warning');
    }

    // Only add "Upload More Files" link if they're able to upload multiple items
    if (this.hasTotalUploadContainerTarget) {
      const uploadLink = document.createElement('a');
      uploadLink.href = window.location;
      uploadLink.classList.add('btn', 'btn-primary');
      uploadLink.innerHTML = 'Upload More Files';
      this.addFilesButtonTarget.after(uploadLink);
    }
    this.cancelUploadsButtonTarget.hidden = true;
  }
}
