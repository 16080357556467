import { Controller } from 'stimulus';

export default class extends Controller {
  addMessage(event) {
    this.loadErrorTableBody = document.getElementById('image_load_error_tbody');
    document.getElementById('image_load_error_container').hidden = false;

    this.row = this.loadErrorTableBody.insertRow(0);
    this.td = this.row.insertCell(-1);
    this.td.innerHTML = this.data.get('lot');
    this.td = this.row.insertCell(-1);
    this.td.innerHTML = this.data.get('type');
    this.td = this.row.insertCell(-1);
    this.td.innerHTML = Object.assign(document.createElement('a'), { href: event.target.src, innerHTML: event.target.src }).outerHTML;
  }
}
