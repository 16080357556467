import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['lotRow']

  setPosition(event) {
    event.preventDefault();
    Array.from(document.getElementsByClassName(`nested-fields ${event.target.dataset.type}`)).forEach((element) => {
      element.getElementsByClassName('position-field')[0].value = -1;
    });
    this.orderTargets();
  }

  orderTargets() {
    Array.from(document.getElementsByClassName('d-flex justify-content-center mb-3')).forEach((container) => {
      const sortables = Array.from(container.getElementsByClassName('nested-fields'));
      sortables.sort((a, b) => (a.getElementsByClassName('position-field')[0].value || 999) - (b.getElementsByClassName('position-field')[0].value || 999));
      sortables.forEach(element => element.remove());
      sortables.forEach(element => container.append(element));
      this.updatePositions(container);
    });
  }

  updatePositions(container) {
    Array.from(container.getElementsByClassName('position-field')).forEach((element, index) => {
      element.value = index;
    });
  }
}
