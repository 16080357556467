import { Controller } from 'stimulus';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

let totalAmount;

export default class extends Controller {
  static targets = ['checkbox', 'selectedAverage', 'selectedCount', 'selectedTotal'];

  connect() {
    this.updateStatistics();
  }

  exportSelected() {
    const checkboxes = this.checkboxTargets.filter(checkbox => checkbox.checked);
    const lotIds = checkboxes.map(lot => lot.defaultValue);
    let url = `sale_reports/csv_export.csv?lots=[${lotIds}]`;
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('sort')) { url += '&sort=number'; }
    window.open(url);
  }

  updateCheckboxes(event) {
    this.checkboxTargets.forEach((object) => {
      const checkbox = object;
      checkbox.checked = event.currentTarget.checked;
    });
    this.updateStatistics();
  }

  updateStatistics() {
    this.updateSelectedCount();
    this.updateSelectedTotal();
    this.updateSelectedAverage();
  }

  updateSelectedCount() {
    const count = this.checkboxTargets.filter(checkbox => checkbox.checked).length;
    this.selectedCountTarget.innerHTML = count;
  }

  updateSelectedTotal() {
    const checkboxes = this.checkboxTargets.filter(checkbox => checkbox.checked);
    totalAmount = 0;
    checkboxes.forEach((checkbox) => {
      const rawPrice = checkbox.dataset.price;
      if (typeof rawPrice !== 'undefined' && rawPrice != null && rawPrice !== '') {
        const sanitizedPrice = rawPrice.replace(/\$|,/g, '');
        const parsedPrice = Number(sanitizedPrice);
        if (!Number.isNaN(parsedPrice)) {
          totalAmount += parsedPrice;
        }
      }
    });
    this.selectedTotalTarget.innerHTML = currencyFormatter.format(totalAmount);
  }

  updateSelectedAverage() {
    const checkboxes = this.checkboxTargets.filter(checkbox => checkbox.checked);
    if (checkboxes.length === 0) {
      this.selectedAverageTarget.innerHTML = '$0.00';
    } else {
      const averageAmount = totalAmount / checkboxes.length;
      this.selectedAverageTarget.innerHTML = currencyFormatter.format(averageAmount);
    }
  }
}
