import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'selectAllCheckbox']

  toggleAllLotCheckboxes() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = this.selectAllCheckboxTarget.checked;
    });
  }

  toggleHeaderCheckbox() {
    const checkBoxesChecked = [...new Set(this.checkboxTargets.map(checkbox => checkbox.checked))];
    if (checkBoxesChecked.includes(true) && checkBoxesChecked.includes(false)) {
      this.selectAllCheckboxTarget.indeterminate = true;
    } else if (checkBoxesChecked.includes(true)) {
      this.selectAllCheckboxTarget.indeterminate = false;
      this.selectAllCheckboxTarget.checked = true;
    } else {
      this.selectAllCheckboxTarget.indeterminate = false;
      this.selectAllCheckboxTarget.checked = false;
    }
  }
}
