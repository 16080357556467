import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['eventRecordingActive', 'input', 'row'];

  update(event) {
    event.preventDefault();
    const url = this.inputTarget.getAttribute('data-url');
    fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json'
      }
    }).then(response => response.text())
      .then((html) => {
        this.rowTarget.insertAdjacentHTML('beforebegin', html);
        this.newRowTarget = this.rowTarget.previousElementSibling;
        this.newRowTarget.classList.add('flash-opacity');
        this.rowTarget.remove();
      });
  }
}
