import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['countdown'];

  connect() {
    let countdown = 31;

    setInterval(() => {
      countdown -= 1;
      if (countdown > 0) {
        this.countdownTarget.innerHTML = `Continuing in ${countdown} seconds`;
      } else {
        window.location.reload();
      }
    }, 1000);
  }
}
