import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'amountInput', 'expenseNumberOfDaysInput', 'expenseTypeInput', 'generalExpenseToggle', 'mileageInput',
    'mileageWrapper', 'notesInput', 'numberOfLotsInput', 'perLotGroupWrapper', 'pricePerDayInput', 'pricePerLotInput'
  ];

  calculateMileageCost() {
    this.mileageInputTarget.value = parseInt(this.mileageInputTarget.value, 10);
    this.amountInputTarget.value = (this.data.get('costPerMile') * this.mileageInputTarget.value).toFixed(2);
  }

  calculatePerLotCost() {
    if (this.pricePerLotInputTarget.value && this.numberOfLotsInputTarget.value) {
      this.amountInputTarget.value = (this.pricePerLotInputTarget.value * this.numberOfLotsInputTarget.value).toFixed(2);
      const note = ['$', this.pricePerLotInputTarget.value, '/lot for ', this.numberOfLotsInputTarget.value, ' lots = $',
        (this.pricePerLotInputTarget.value * this.numberOfLotsInputTarget.value)];
      this.notesInputTarget.value = note.join('');
      this.pricePerDayInputTarget.value = '';
      this.expenseNumberOfDaysInputTarget.value = '';
    } else if (this.pricePerDayInputTarget.value > 0 && this.expenseNumberOfDaysInputTarget.value > 0) {
      this.amountInputTarget.value = (this.pricePerDayInputTarget.value * this.expenseNumberOfDaysInputTarget.value).toFixed(2);
      const note = ['$', this.pricePerDayInputTarget.value, '/day for ', this.expenseNumberOfDaysInputTarget.value, ' days = $',
        (this.pricePerDayInputTarget.value * this.expenseNumberOfDaysInputTarget.value)];
      this.notesInputTarget.value = note.join('');
      this.pricePerLotInputTarget.value = '';
      this.numberOfLotsInputTarget.value = '';
    } else {
      this.amountInputTarget.value = '';
      this.notesInputTarget.value = '';
    }
  }

  remove(event) {
    event.preventDefault();
    this.element.remove();
  }

  selectExpenseType() {
    this.mileageWrapperTarget.hidden = this.expenseTypeInputTarget.value !== 'Mileage';
    this.mileageWrapperTarget.disabled = this.expenseTypeInputTarget.value !== 'Mileage';
    this.perLotGroupWrapperTarget.hidden = this.expenseTypeInputTarget.value !== 'Per Lot';
    if (this.expenseTypeInputTarget.value !== 'Mileage') { this.mileageInputTarget.value = null; }
  }
}
