import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['lotsInput'];

  assign(event) {
    event.preventDefault();
    const lotIds = [...this.lotsInputTarget.selectedOptions].map(lot => lot.value);
    fetch(this.element.action, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ lot_ids: lotIds })
    }).then(response => response.text())
      .then((html) => {
        document.querySelector(this.element.getAttribute('data-element-id')).innerHTML = html;
        $(this.element.closest('.modal')).modal('hide');
      });
  }

  unassign(event) {
    event.preventDefault();
    fetch(event.target.href, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json'
      }
    }).then(response => response.text())
      .then((html) => {
        this.element.innerHTML = html;
      });
  }
}
