import { Controller } from 'stimulus';

const Accept = window.accept;
let month;
let year;

export default class extends Controller {
  static targets = [
    'alertMessage', 'approvalInputs', 'closeModalButton', 'continueFooter', 'creditCardInputs', 'cvv', 'date',
    'infoMessage', 'loadingIcon', 'number', 'postCreditCardMonth', 'postCreditCardYear', 'postTransactionId',
    'postVerificationForm', 'verifyCreditCardButton', 'verifyCardInputFooter'
  ];

  verifyCreditCard(event) {
    event.preventDefault();
    this.alertMessage('', true);

    const number = this.numberTarget.value.replace(/[^0-9]/g, '');
    month = this.dateTargets[0].value.length === 1 ? `0${this.dateTargets[0].value}` : this.dateTargets[0].value;
    year = this.dateTargets[1].value;
    const cvv = this.cvvTarget.value;

    if (number.length <= 12 || number.length >= 17) {
      this.alertMessage('Invalid credit card number length');
      return;
    }

    if (month.length === 0 || year.length === 0) {
      this.alertMessage('Date not selected');
      return;
    }

    if (cvv.length !== 3 && cvv.length !== 4) {
      this.alertMessage('Invalid CVV length');
      return;
    }

    const authData = {};
    authData.clientKey = this.data.get('publicKey');
    authData.apiLoginID = this.data.get('loginKey');
    const cardData = {};
    cardData.cardNumber = number;
    cardData.month = month;
    cardData.year = year.slice(2);
    cardData.cardCode = cvv;
    const secureData = {};
    secureData.authData = authData;
    secureData.cardData = cardData;
    Accept.dispatchData(secureData, (response) => {
      if (response.messages.resultCode === 'Error') {
        let i = 0;
        const errorMessages = [];
        while (i < response.messages.message.length) {
          errorMessages.push(`${response.messages.message[i].code}: ${response.messages.message[i].text}`);
          i += 1;
        }
        this.alertMessage(errorMessages.join(', '));
      } else {
        this.processOpaqueData(response.opaqueData);
      }
    });
  }

  alertMessage(message, hidden = false) {
    this.infoMessageTarget.innerHTML = '';
    this.infoMessageTarget.hidden = true;
    this.alertMessageTarget.innerHTML = message;
    this.alertMessageTarget.hidden = hidden;
    this.verifyCreditCardButtonTarget.disabled = false;
  }

  infoMessage(message, hidden = false) {
    this.alertMessageTarget.innerHTML = '';
    this.alertMessageTarget.hidden = true;
    this.infoMessageTarget.innerHTML = message;
    this.infoMessageTarget.hidden = hidden;
    this.verifyCreditCardButtonTarget.disabled = true;
  }

  processOpaqueData(opaqueData) {
    const formURL = this.data.get('verifyCreditCardUrl');
    const url = `${formURL}?${new URLSearchParams(opaqueData).toString()}`;
    this.creditCardInputsTarget.hidden = true;
    this.loadingIconTarget.hidden = false;
    fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (response.redirected) {
        window.location.reload();
      } else {
        return response.json();
      }
    }).then((data) => {
      if (data.success === true) {
        this.infoMessage(data.message);
        this.cvvTarget.disabled = true;
        this.dateTargets[0].disabled = true;
        this.dateTargets[1].disabled = true;
        this.postCreditCardMonthTarget.value = month;
        this.postCreditCardYearTarget.value = year;
        this.postTransactionIdTarget.value = data.transaction_id;
        this.numberTarget.disabled = true;

        this.verifyCardInputFooterTarget.hidden = true;
        this.approvalInputsTarget.hidden = false;
        this.loadingIconTarget.hidden = true;
      } else {
        this.alertMessage(data.message);
        this.creditCardInputsTarget.hidden = false;
        this.loadingIconTarget.hidden = true;
      }
    });
  }

  sanitizeCreditCardNumber() {
    this.numberTarget.value = this.numberTarget.value.replace(/[^0-9\s-]/g, '');
  }

  updateApproval(event) {
    this.alertMessage('', true);
    event.preventDefault();
    this.loadingIconTarget.hidden = false;
    this.postVerificationFormTarget.hidden = true;
    const formURL = this.data.get('postVerificationUrl');
    const formData = new FormData(this.postVerificationFormTarget);
    const searchParams = new URLSearchParams(formData);
    const url = `${formURL}?${searchParams}`;
    fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json'
      }
    }).then(response => response)
      .then((data) => {
        if (data.redirected) {
          this.alertMessage('Failed to update approval. CC info will not be visible after leaving this page.');
          this.continueFooterTarget.hidden = false;
          this.creditCardInputsTarget.hidden = false;
        } else if (data.ok) {
          this.infoMessage('Approval updated. CC info will not be visible after leaving this page.');
          this.closeModalButtonTarget.hidden = true;
          this.continueFooterTarget.hidden = false;
          this.creditCardInputsTarget.hidden = false;
        } else {
          this.alertMessage('Failed to update approval');
          this.postVerificationFormTarget.hidden = false;
        }
        this.loadingIconTarget.hidden = true;
      });
  }
}
