import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['card']

  stopMonitoring(event) {
    const eventId = event.target.getAttribute('data-event-id');
    const url = new URL(window.location.href);
    const params = url.searchParams;
    const excludedIds = params.get('exclude_id') ? params.get('exclude_id').split(',') : [];
    if (!excludedIds.includes(eventId)) {
      excludedIds.push(eventId);
      excludedIds.forEach((id) => {
        params.append('exclude_id[]', id);
      });
      window.history.pushState(null, '', url.toString());
    }
    this.cardTarget.remove();
  }
}
