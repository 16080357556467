import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['number'];

  connect() {
    this.numberTargets.forEach((target) => {
      target.addEventListener('change', (event) => { this.updateNumber(event.target); });
      this.updateNumber(target);
    });
  }

  updateNumber(target) {
    const value = Number.parseFloat(target.value).toFixed(2);

    if (value >= 0) { target.value = value; }
  }
}
