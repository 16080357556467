import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${window.google_maps_api_key}&callback=initMap`;
    script.async = true;
    document.head.appendChild(script);

    window.initMap = () => {
      this.map = new google.maps.Map(document.getElementById('map'), {
        zoom: 4,
        center: { lat: 40.806862, lng: -96.681679 }
      });

      fetch(`/admin/clerking/events/${window.event_id}/purchase_locations_geo`)
        .then(response => response.text())
        .then((json) => {
          this.features = JSON.parse(json);
          this.features.features.forEach((feature) => {
            if (feature.geometry && feature.geometry.coordinates) { this.map.data.addGeoJson(feature); }
          });
          this.infoWindow = new google.maps.InfoWindow();
          this.map.data.addListener('click', (event) => {
            const feature = event.feature;
            let html = `<h5>${feature.getProperty('name')}</h5><div>${feature.getProperty('address')}</div>`;
            if (feature.getProperty('nearby')) { html += '<div class="text-danger">Address not found, closest match shown</div>'; }
            html += `<a href='${feature.getProperty('link')}' target='_blank'>View Buyer</a>`;
            this.infoWindow.setContent(html);
            this.infoWindow.setPosition(event.latLng);
            this.infoWindow.setOptions({ pixelOffset: new google.maps.Size(0, -30) });
            this.infoWindow.open(this.map);
          });
        });
    };
  }
}
