import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['approveForm', 'lotOptionsContainer'];

  approve(event) {
    event.preventDefault();
    this.formData = new FormData(this.approveFormTarget);

    fetch(this.element.dataset.url, {
      method: 'POST',
      headers: {
        headers: { 'X-CSRF-Token': document.querySelector("[name='csrf-token']").content }
      },
      body: this.formData
    }).then(response => response.text())
      .then((html) => {
        this.row = document.getElementById(this.element.dataset.rowId);
        this.row.insertAdjacentHTML('beforebegin', html);
        this.newTarget = this.row.previousElementSibling;
        this.newTarget.classList.add('flash-opacity');
        this.row.remove();
        $('.modal.fade.show').modal('hide');
      });
  }

  hideLotOptionsContainer(event) {
    this.lotOptionsContainerTarget.hidden = !event.target.checked;
    const checkboxes = this.lotOptionsContainerTarget.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.disabled = !event.target.checked;
    });
  }
}
