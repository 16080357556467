import { Controller } from 'stimulus';

export default class extends Controller {
  log(msg, object = null) {
    const [,, stackLine] = new Error().stack.split('\n');
    const action = stackLine.match(/at .*?\.(.*?)\s/)[1].toUpperCase();
    const controller = this.context.scope.identifier.toUpperCase();
    if (object === null) {
      console.log(`[${controller}] [${action}] ${msg || ''}`);
    } else {
      console.log(`[${controller}] [${action}] ${msg || ''}`, object);
    }
  }
}
