import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['carouselItems'];

  pauseVideos() {
    this.carouselItemsTargets.forEach((item) => {
      const e = item.querySelector('img')
             || item.querySelector('video');
      if (e.tagName === 'VIDEO') {
        e.pause();
      }
    });
  }

  selectMedia(event) {
    this.pauseVideos();

    const carouselIndex = event.target.dataset.slideTo
                       || event.target.parentNode.dataset.slideTo
                       || event.target.parentNode.parentNode.dataset.slideTo;

    const e = this.carouselItemsTargets[carouselIndex].querySelector('img')
           || this.carouselItemsTargets[carouselIndex].querySelector('video');
    if (e.tagName === 'VIDEO') {
      e.currentTime = 0;
      e.play();
    }
  }
}
