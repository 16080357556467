import { Controller } from 'stimulus';
import { Sortable } from 'sortablejs';

export default class extends Controller {
  static targets = ['mediaContainer', 'submitButton'];

  connect() {
    this.orderMedia();
    this.createSortable(this, this.mediaContainerTarget);
  }

  createSortable(_this, target) {
    Sortable.create(target, {
      animation: 100,

      onEnd() {
        _this.updatePositions();
      }
    });
  }

  orderMedia() {
    const media = Array.from(this.mediaContainerTarget.getElementsByClassName('card'));
    media.sort((a, b) => (a.getElementsByClassName('position-field')[0].value || 999) - (b.getElementsByClassName('position-field')[0].value || 999));
    media.forEach(element => element.remove());
    media.forEach(element => this.mediaContainerTarget.append(element));
    this.mediaContainerTarget.hidden = false;
    this.submitButtonTarget.hidden = false;
    this.updatePositions();
  }

  updatePositions() {
    Array.from(this.mediaContainerTarget.getElementsByClassName('position-field')).forEach((element, index) => {
      element.value = index;
    });
  }
}
