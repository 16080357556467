import { Controller } from 'stimulus';

const Accept = window.accept;
let failedAttempts = 0;
let month;

export default class extends Controller {
  static targets = [
    'address', 'alertMessage', 'approvalBody', 'city', 'closeModalButton', 'continueFooter', 'creditCardInputs', 'country', 'cvv', 'date',
    'dayPhone', 'eveningPhone', 'firstName', 'lastName', 'loadingIcon', 'mobilePhone', 'modal', 'number',
    'postTransactionId', 'state', 'verifyCreditCardButton', 'verifyCardInputFooter', 'zip'
  ];

  verifyCreditCard(event) {
    event.preventDefault();
    this.alertMessage('', true);
    this.verifyCreditCardButtonTarget.disabled = true;
    this.modalTarget.scrollTo(0, 0);

    const number = this.numberTarget.value.replace(/[^0-9]/g, '');
    month = this.dateTargets[0].value.length === 1 ? `0${this.dateTargets[0].value}` : this.dateTargets[0].value;
    if (this.invalidFields(number)) { return; }

    const authData = {};
    authData.clientKey = this.data.get('publicKey');
    authData.apiLoginID = this.data.get('loginKey');
    const cardData = {};
    cardData.cardNumber = number;
    cardData.month = month;
    cardData.year = this.dateTargets[1].value.slice(2);
    cardData.cardCode = this.cvvTarget.value;
    const secureData = {};
    secureData.authData = authData;
    secureData.cardData = cardData;
    Accept.dispatchData(secureData, (response) => {
      if (response.messages.resultCode === 'Error') {
        let i = 0;
        const errorMessages = [];
        while (i < response.messages.message.length) {
          errorMessages.push(`${response.messages.message[i].code}: ${response.messages.message[i].text}`);
          i += 1;
        }
        this.alertMessage(errorMessages.join(', ').replace('E_WC_05: ', '').replace('.', ''));
      } else {
        this.processOpaqueData(response.opaqueData);
      }
    });
  }

  alertMessage(message, hidden = false) {
    this.alertMessageTarget.innerHTML = message;
    this.alertMessageTarget.hidden = hidden;
    this.verifyCreditCardButtonTarget.disabled = false;
  }

  invalidFields(numberValue) {
    const errorMessages = [];

    if (numberValue.length <= 12 || numberValue.length >= 17) { errorMessages.push('Invalid credit card number length <br>'); }
    if (this.dateTargets[0].value.length === 0) { errorMessages.push('Expiration month not selected <br>'); }
    if (this.dateTargets[1].value.length === 0) { errorMessages.push('Expiration year not selected <br>'); }
    if (this.cvvTarget.value.length !== 3 && this.cvvTarget.value.length !== 4) { errorMessages.push('Invalid CVV length <br>'); }
    if (this.dayPhoneTarget.value.length === 0 && this.eveningPhoneTarget.value.length === 0 && this.mobilePhoneTarget.value.length === 0) {
      errorMessages.push('Mobile phone required if no Day or Evening phone entered');
    }
    if (this.firstNameTarget.value.length < 2 || this.firstNameTarget.value.length > 50) { errorMessages.push('Invalid first name length <br>'); }
    if (this.lastNameTarget.value.length < 2 || this.lastNameTarget.value.length > 50) { errorMessages.push('Invalid last name length <br>'); }
    if (this.addressTarget.value.length < 3 || this.addressTarget.value.length > 60) { errorMessages.push('Invalid address length <br>'); }
    if (this.cityTarget.value.length < 2 || this.cityTarget.value.length > 40) { errorMessages.push('Invalid city length <br>'); }
    if (this.stateTarget.value.length !== 2) { errorMessages.push('Invalid state <br>'); }
    if (this.zipTarget.value.length < 5 || this.zipTarget.value.length > 20) { errorMessages.push('Invalid zip length <br>'); }
    if (this.countryTarget.value.length !== 2) { errorMessages.push('Invalid country <br>'); }
    if (errorMessages.length > 0) {
      this.alertMessage(errorMessages.join(' '));
      return true;
    }

    return false;
  }

  processOpaqueData(opaqueData) {
    const formURL = this.data.get('verifyCreditCardUrl');

    let billTo = {};
    billTo.address = this.addressTarget.value;
    billTo.city = this.cityTarget.value;
    billTo.country = this.countryTarget.value;
    billTo.first_name = this.firstNameTarget.value;
    billTo.last_name = this.lastNameTarget.value;
    billTo.month = this.dateTargets[0].value;
    billTo.state = this.stateTarget.value;
    billTo.year = this.dateTargets[1].value;
    billTo.zip = this.zipTarget.value;
    billTo = new URLSearchParams(billTo).toString();

    let phoneNumbers = {};
    phoneNumbers.day_phone = this.dayPhoneTarget.value;
    phoneNumbers.evening_phone = this.eveningPhoneTarget.value;
    phoneNumbers.mobile_phone = this.mobilePhoneTarget.value;
    phoneNumbers = new URLSearchParams(phoneNumbers).toString();

    const url = `${formURL}?${new URLSearchParams(opaqueData).toString()}&${billTo}&${phoneNumbers}`;

    this.creditCardInputsTarget.hidden = true;
    this.loadingIconTarget.hidden = false;

    fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (response.redirected) {
        window.location.reload();
      } else {
        return response.json();
      }
    }).then((data) => {
      if (data.success === true) {
        this.alertMessage('', true);
        this.addressTarget.disabled = true;
        this.cityTarget.disabled = true;
        this.countryTarget.disabled = true;
        this.cvvTarget.disabled = true;
        this.dateTargets[0].disabled = true;
        this.dateTargets[1].disabled = true;
        this.firstNameTarget.disabled = true;
        this.lastNameTarget.disabled = true;
        this.numberTarget.disabled = true;
        this.stateTarget.disabled = true;
        this.zipTarget.disabled = true;

        this.approvalBodyTarget.hidden = false;
        this.continueFooterTarget.hidden = false;
        this.loadingIconTarget.hidden = true;
        this.verifyCardInputFooterTarget.hidden = true;
      } else {
        this.creditCardInputsTarget.hidden = false;
        this.loadingIconTarget.hidden = true;
        failedAttempts += 1;
        if (failedAttempts >= 2) {
          window.location = '/disable_instant_approval';
        } else {
          this.alertMessage(data.message);
        }
      }
    });
  }

  sanitizeCreditCardNumber() {
    this.numberTarget.value = this.numberTarget.value.replace(/[^0-9\s-]/g, '');
  }
}
