import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['timeDisplay', 'videoPreview'];

  connect() {
    const controller = this;
    this.videoPreviewTarget.ontimeupdate = function displayVideoTime() {
      const videoTime = new Date(controller.videoPreviewTarget.currentTime * 1000);
      const startTime = new Date(controller.videoPreviewTarget.dataset.startTime);
      const displayTime = new Date(videoTime.getTime() + startTime.getTime());
      controller.timeDisplayTarget.innerHTML = displayTime.toLocaleTimeString();
    };
  }

  setTime(event) {
    const videoTime = this.videoPreviewTarget.currentTime * 1000;
    const startTime = new Date(this.videoPreviewTarget.dataset.startTime);
    const displayTime = new Date(videoTime + startTime.getTime());
    document.querySelector(`${event.target.id}`)._flatpickr.setDate(displayTime, true); // eslint-disable-line no-underscore-dangle
  }
}
