import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['polymorphicType', 'content', 'idField', 'idWrapper', 'label', 'labelField'];

  connect() {
    this.polymorphicTypeTarget.addEventListener('change', (event) => {
      this.polymorphicType(event.target.value);
    });
  }

  polymorphicType(value) {
    if (value === 'Company') {
      this.idWrapperTarget.hidden = true;
      this.labelFieldTarget.value = '';
      this.labelTarget.innerHTML = 'Company <abbr title="required">*</abbr>';
    } else if (value === 'Domain') {
      this.idWrapperTarget.hidden = false;
      this.labelFieldTarget.setAttribute('data-target', '#domain-search-modal, admin--domain-search.labelField admin--polymorphic-select.labelField');
      this.labelFieldTarget.value = 'Select domain...';
      this.labelTarget.innerHTML = 'Domain <abbr title="required">*</abbr>';
    } else {
      this.idWrapperTarget.hidden = false;
      this.labelFieldTarget.setAttribute('data-target', '#event-search-modal, admin--event-search.labelField admin--polymorphic-select.labelField');
      this.labelFieldTarget.value = 'Select event...';
      this.labelTarget.innerHTML = 'Event <abbr title="required">*</abbr>';
    }
    this.idFieldTarget.value = '';
  }

  populateContent(event) {
    this.contentTarget.value = event.target.innerText;
  }
}
