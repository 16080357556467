// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

import 'bootstrap';
require('expose-loader?$!jquery');

import { Application } from 'stimulus';
import { definitionsFromContext } from "stimulus/webpack-helpers"
const application = Application.start();
const context = require.context("controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

// Initialize popovers
$('[data-toggle="popover"]').popover();

import bsCustomFileInput from 'bs-custom-file-input'
$(document).ready(function () {
  bsCustomFileInput.init()
})
